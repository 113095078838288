export class TipoAyudaConContrato {
    public idTipoAyuda: number;
    public descripcion: string;
    public contrato: string;
    public exentaIRPF: boolean;
    public tiposContrato: TipoAyudaContrato[];

    constructor() { }
}

export class TipoAyudaContrato {
    public idTipoAyudaContrato: number;
    public descripcion: string;
    public idTipoAyuda: number;
    public idContractType: number;
    public estado: string;
    public codigoNomina: string;
    public incluido: boolean;
    public porcentajeAyuda: number;

    constructor() {}
}