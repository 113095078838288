import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AprobacionhrComponent } from './components/ayudas/aprobacionhr/aprobacionhr.component';
import { AyudaComponent } from './components/ayudas/ayuda/ayuda.component';
import { BeneficiarioComponent } from './components/ayudas/beneficiario/beneficiario.component';
import { CierreComponent } from './components/ayudas/cierre/cierre.component';
import { EstudiosComponent } from './components/ayudas/estudios/estudios.component';
import { PresupuestoComponent } from './components/ayudas/presupuesto/presupuesto.component';
import { RevisarayudasComponent } from './components/ayudas/revisarayudas/revisarayudas.component';
import { RevisarpresupuestosComponent } from './components/ayudas/revisarpresupuestos/revisarpresupuestos.component';
import { TipoayudasComponent } from './components/ayudas/tipoayudas/tipoayudas.component';
import { BajasComponent } from './components/bajas/bajas/bajas.component';
import { RevisarbajasComponent } from './components/bajas/revisarbajas/revisarbajas.component';
import { InformeayudasComponent } from './components/informes/informeayudas/informeayudas.component';
import { InformebajasComponent } from './components/informes/informebajas/informebajas.component';
import { InformenominasComponent } from './components/informes/informenominas/informenominas.component';
import { InformepresupuestosComponent } from './components/informes/informepresupuestos/informepresupuestos.component';
import { LoginComponent } from './components/login/login.component';
import { SolicitudComponent } from './components/ayudas/campamentos/solicitud/solicitud.component';
import { RevisionComponent } from './components/ayudas/campamentos/revision/revision.component';

const routes: Routes = [
  {path: 'ayudas', component: AyudaComponent},
  {path: 'beneficiarios', component: BeneficiarioComponent},
  {path: 'cierres', component: CierreComponent},
  {path: 'tiposAyudas', component: TipoayudasComponent},
  {path: 'aprobacion', component: AprobacionhrComponent},
  {path: 'informes', component: InformeayudasComponent},
  {path: 'informeNominas', component: InformenominasComponent},
  {path: 'revisarayudas', component: RevisarayudasComponent},
  {path: 'presupuestos', component: PresupuestoComponent},
  {path: 'revisarpresupuestos', component: RevisarpresupuestosComponent},
  {path: 'informepresupuestos', component: InformepresupuestosComponent},
  {path: 'revisarbajas', component: RevisarbajasComponent},
  {path: 'bajas', component: BajasComponent},
  {path: 'informebajas', component: InformebajasComponent},
  {path: 'login', component: LoginComponent},
  {path: 'ayudasestudios', component: EstudiosComponent},
  {path: 'solicitudcampamentos', component: SolicitudComponent},
  {path: 'revisioncampamentos', component: RevisionComponent},
  //{ path: '',   redirectTo: '/', pathMatch: 'full' }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
