<form id="bajasFrm" name="bajasFrm" #bajasFrm="ngForm" (ngSubmit)="submitBaja(bajasFrm)" class="needs-validation pt-3 text-alcoa" novalidate>
    <div class="row border-bottom border-alcoa">
        <h2 class="col-12">Modificar I.T.</h2>
    </div>
    <div class="row">
        <div class="form-group row pt-1">
            <div class="col-lg-9 col-12">
                <div class="row">
                    <label id="lblFechaIni" for="fechaIni" class="col-lg-2 col-md-2 col-sm-4 align-self-center col-form-label col-form-label-sm fw-bold">{{ 'FORM.FECHA_INICIO' | translate }}:</label>
                    <div class="col-4">
                        <input matInput [matDatepicker]="picker" class="text-alcoa text-center form-control form-control-sm form-control-calendar" [max]="hoy"
                            [ngClass]="fechaIni.hasError('required') && bajasFrm.submitted ? 'obligatorio' : ''" [(ngModel)]="baja.fechaInicio" name="fechaIni" #fechaIni="ngModel" readonly
                            [required]="baja.idTipoIncapacidadTemporal != 2">
                        <button type="button" class="btn btn-sm text-alcoa" (click)="clearDate(fechaIni)">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                        <mat-datepicker-toggle matSuffix [for]="picker" class="text-alcoa"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                    <label id="lblFechaFin" for="fechaFin" class="col-lg-2 col-md-2 col-sm-4 align-self-center col-form-label col-form-label-sm fw-bold">{{ 'FORM.FECHA_FIN' | translate }}:</label>
                    <div class="col-4">
                        <input matInput [matDatepicker]="pickerFin" class="text-alcoa text-center form-control form-control-sm form-control-calendar" [min]="baja.fechaInicio"
                            [ngClass]="fechaFin.hasError('matDatepickerMin') && bajasFrm.submitted ? 'obligatorio' : ''" [(ngModel)]="baja.fechaFin" name="fechaFin" #fechaFin="ngModel" readonly>
                        <button type="button" class="btn btn-sm text-alcoa" (click)="clearDate(fechaFin)">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                        <mat-datepicker-toggle matSuffix [for]="pickerFin" class="text-alcoa"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFin></mat-datepicker>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button mat-dialog-close class="btn btn-secondary float-end ms-2" cdkFocusInitial>
                    <fa-icon [icon]="faDoorClosed"></fa-icon> Cancelar
                </button>
                <button type="submit" class="btn btn-alcoa float-end">
                    <fa-icon [icon]="faSave"></fa-icon> Guardar
                </button>
            </div>
        </div>
    </div>
</form>
