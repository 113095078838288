import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Aviso } from 'src/app/model/aviso';

@Component({
  selector: 'app-denegardialog',
  templateUrl: './denegardialog.component.html',
  styleUrls: ['./denegardialog.component.css']
})
export class DenegardialogComponent implements OnInit {
  comentario: string;
  valido: boolean = true;

  constructor(public dialogRef: MatDialogRef<DenegardialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Aviso) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  aceptar(): void {
    this.valido = true;
    if (this.comentario) {
      this.dialogRef.close(this.comentario);
    } else {
      this.valido = false;
    }
  }
}
