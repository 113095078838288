import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faSave, faEraser, faTimes, faPaperclip, faQuestion, faCheck, faCheckDouble, IconDefinition, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmaciondialogComponent } from 'src/app/components/dialog/confirmaciondialog/confirmaciondialog.component';
import { Aviso } from 'src/app/model/aviso';
import { Presupuesto } from 'src/app/model/presupuesto';
import { AyudasService } from 'src/app/services/ayudas.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { DenegardialogComponent } from '../../aprobacionhr/denegardialog/denegardialog.component';

class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return `${date.getFullYear()}`;
  }
}

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY' },
        display: { dateInput: 'YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' },
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevisionComponent implements OnInit {
  faSave = faSave;
  faEraser = faEraser;
  faTimes = faTimes;
  faPaperClip = faPaperclip;

  form = new FormGroup({
    date: new FormControl()
  });
  cantidad: number;
  modificarImporte: boolean = false;

  // Tabla
  dataSource: MatTableDataSource<Presupuesto>;
  displayColumns: string[] = ['id', 'contrato', 'nombre', 'beneficiario', 'ano', 'estado', 'importe', 'observaciones', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Presupuesto;

  constructor(private presupuestosService: PresupuestosService, private translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    let ano: number = new Date().getFullYear();
    this.form.controls.date.setValue(new Date());
    this.buscarSolicitudesCamp();
  }

  buscarSolicitudesCamp(): void {
    let ano = new Date(this.form.controls.date.value).getFullYear();
    this.presupuestosService.getSolicitudesCampamentos(ano).subscribe(result => {
      this.dataSource.data = result;
      this.dataSource = this.dataSource;
    });
  }

  guardar(): void {
    if (this.cantidad) {
      let importe = this.cantidad;
      this.dataSource.data.forEach(p => {
        p.importe = importe;
        this.presupuestosService.modificacionPresupuesto(p).subscribe();
      });

      delete this.cantidad;
      this.modificarImporte = false;
    }
  }

  confirmarBorrarSolicitud(presupuesto: Presupuesto): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a eliminar la solicitud de campamento de ${presupuesto.beneficiario}. ¿Desea continuar?`;

    if (presupuesto.idEstado == 1) {
      const dialogRef = this.dialog.open(DenegardialogComponent, {
        width: "400px",
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Si el presupuesto estaba borrado se hace un borrado logico (idEstado = 4)
          presupuesto.idEstado = 4;
          presupuesto.observaciones = result;
          this.presupuestosService.modificacionPresupuesto(presupuesto).subscribe(result => {
            this.buscarSolicitudesCamp();
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
        width: "400px",
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.borraPresupuesto(presupuesto);
        }
      });
    }
  }

  borraPresupuesto(presupuesto: Presupuesto): void {
    this.presupuestosService.eliminaPresupuesto(presupuesto.idPresupuesto).subscribe({
      next: result => {
        if (result) {
          this.buscarSolicitudesCamp();
        }
      },
      error: error => console.error(error)
    });
  }

  public getIconoEstado(presupuesto: Presupuesto): IconDefinition {
    let icono: IconDefinition;
    switch (presupuesto.idEstado) {
      case 0:
        icono = faQuestion;
        break;
      case 1:
        icono = faCheck;
        break;
      case 2:
        icono = faTimes;
        break;
      case 3:
        icono = faCheckDouble;
        break;
      default:
        break;
    }

    return icono;
  }

  public getClaseEstado(presupuesto: Presupuesto): string {
    if (presupuesto.idEstado == 0) {
      return "text-warning";
    } else if (presupuesto.idEstado == 1) {
      return "text-success";
    } else if (presupuesto.idEstado == 2) {
      return "text-danger";
    } else {
      return "";
    }
  }

  public onYearSelected(date: Date, datepicker: MatDatepicker<Date>) {
    const normalizedYear = date.getFullYear();
    this.form.controls.date.setValue(new Date(normalizedYear, 12, 0));
    datepicker.close();
    this.buscarSolicitudesCamp();
  }
}
