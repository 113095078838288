import { Component, OnInit, ViewChild } from '@angular/core';
import { faCheck, faCheckCircle, faExclamationTriangle, faFileExcel, faPaperclip, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Aviso } from 'src/app/model/aviso';
import { Presupuesto } from 'src/app/model/presupuesto';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { AvisodialogComponent } from '../../dialog/avisodialog/avisodialog.component';
import { DenegardialogComponent } from '../aprobacionhr/denegardialog/denegardialog.component';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-revisarpresupuestos',
  templateUrl: './revisarpresupuestos.component.html',
  styleUrls: ['./revisarpresupuestos.component.css']
})
export class RevisarpresupuestosComponent implements OnInit {
  faTimes = faTimes;
  faCheck = faCheck;
  faPaperClip = faPaperclip;
  faExcel = faFileExcel;

  // Tabla
  dataSource: MatTableDataSource<Presupuesto>;
  displayColumns: string[] = ['ayuda', 'fecha', 'contrato', 'empleado', 'beneficiario', 'importe', 'estado', 'adjunto', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Presupuesto;

  constructor(private presupuestosService: PresupuestosService, private datePipe: DatePipe, public translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buscar();
  }

  buscar(): void {
    this.presupuestosService.getPresupuestosPendientes().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  aprobar(presupuesto: Presupuesto): void {
    presupuesto.idEstado = 1;
    this.presupuestosService.modificacionPresupuesto(presupuesto).subscribe(result => {
      let aviso: Aviso = new Aviso();
      aviso.titulo = "Aprobado";
      aviso.icono = faCheckCircle;
      aviso.claseIcono = "text-success";
      aviso.mensaje = `Se ha Aprobado el presupuesto`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        data: aviso
      });

      // Enviar mail al usuario
      this.presupuestosService.enviarMailPrespuesto(presupuesto).subscribe();

      dialogRef.afterClosed().subscribe(result => {
        this.buscar();
      });
    });
  }

  confirmarAprobar(presupuesto: Presupuesto): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "¡¡¡¡Atención!!!!";
    aviso.mensaje = `Va a APROBAR el presupuesto. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.aprobar(presupuesto);
      }
    });
  }

  confirmarDenegar(presupuesto: Presupuesto): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a denegar el presupuesto. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(DenegardialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        presupuesto.observaciones = result;
        this.denegar(presupuesto);
      }
    });
  }

  denegar(presupuesto: Presupuesto): void {
    presupuesto.idEstado = 2;
    this.presupuestosService.modificacionPresupuesto(presupuesto).subscribe(result => {
      let aviso: Aviso = new Aviso();
      aviso.titulo = "Denegado";
      aviso.icono = faTimesCircle;
      aviso.claseIcono = "text-danger";
      aviso.mensaje = `Se ha DENEGADO el presupuesto`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        data: aviso
      });

      // Enviar mail al usuario
      this.presupuestosService.enviarMailPrespuesto(presupuesto).subscribe();

      dialogRef.afterClosed().subscribe(result => {
        this.buscar();
      });
    });
  }

  verFichero(presupuesto: Presupuesto): void {
    this.presupuestosService.getPresupuestoAdjunto(presupuesto.idPresupuestoAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  exportar(): void {
    if (this.dataSource != undefined) {
      var prepare = [];
      this.dataSource.data.forEach(a => {
        var tempObj = [];
        tempObj.push(a.descTipoAyuda);
        tempObj.push(this.datePipe.transform(a.fecha, "dd/MM/yyyy"));
        tempObj.push(a.empleado);
        tempObj.push(a.beneficiario);
        tempObj.push(a.importe);
        prepare.push(tempObj);
      });
      const workSheet = XLSX.utils.json_to_sheet([]);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();

      //Had to create a new workbook and then add the header
      var Heading = [['Ayuda', 'Fecha', 'Empleado', 'Beneficiario', 'Importe']];
      XLSX.utils.sheet_add_aoa(workSheet, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, prepare, { origin: 'A2', skipHeader: true });

      XLSX.utils.book_append_sheet(workBook, workSheet, 'InformePresupuestos');
      XLSX.writeFile(workBook, 'InformePresupuestos.xlsx');
    }
  }
}
