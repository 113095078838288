import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import { Empleado } from 'src/app/model/empleado';

@Component({
  selector: 'app-usuariosdialog',
  templateUrl: './usuariosdialog.component.html',
  styleUrls: ['./usuariosdialog.component.css']
})
export class UsuariosdialogComponent implements OnInit {
  faDoorClosed = faDoorClosed;

  // Tabla
  dataSource: MatTableDataSource<Empleado>;
  displayColumns: string[] = ['nombre', 'login', 'expediente'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<UsuariosdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Empleado[]) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  seleccionarEmpleado(empleado: Empleado): void {
    this.dialogRef.close(empleado);
  }

}
