<div class="row">
    <label class="col-4 col-xl-2 col-form-label col-form-label-sm fw-bold" for="expediente" id="lblExpediente">{{ 'FORM.EXPEDIENTE' | translate }}:</label>
    <div class="col-8 col-xl-2">
        <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm" id="expediente" [(ngModel)]="expediente" [disabled]="empleado.idUsuario" (keyup.enter)="buscarEmpleado()">
            <button id="btnExpediente" class="btn btn-outline-secondary" type="button" data-toggle="modal" data-placement="top" title="Buscar Empleado" (click)="buscarEmpleado()"
                [disabled]="empleado.idUsuario">
                <fa-icon class="text-alcoa" [icon]="faSearch"></fa-icon>
            </button>
        </div>
    </div>
    <div class="col-4 col-xl-2 mt-2 mt-xl-0">
        <label class="col-form-label col-form-label-sm fw-bold" for="nombre" id="lblNombre">{{ 'FORM.NOMBRE' | translate }}:</label>
    </div>
    <div class="col-7 col-xl-5 mt-2 mt-xl-0">
        <div class="input-group input-group-sm">
            <input type="text" class="form-control form-control-sm" id="nombre" [(ngModel)]="empleado.nombre" [disabled]="empleado.idUsuario" (keyup.enter)="buscarEmpleado()">
            <button id="btnExpediente" class="btn btn-outline-secondary" type="button" data-toggle="modal" data-placement="top" title="Buscar Empleado" (click)="buscarEmpleado()"
                [disabled]="empleado.idUsuario">
                <fa-icon class="text-alcoa" [icon]="faSearch"></fa-icon>
            </button>
        </div>
    </div>
    <div class="col-1 mt-2 mt-xl-0">
        <button id="limpiaUsuario" type="button" class="btn btn-alcoa-PMS320 btn-sm float-end ml-1" (click)="limpiarUsuario()" *ngIf="verLimpiar">
            <fa-icon [icon]="faEraser"></fa-icon>
        </button>
    </div>
</div>