<div class="container-fluid text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">{{ 'AYUDAS.APROBACION_TITULO' | translate }}</h1>
    </div>
    <div class="pt-2" style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FORM.FECHA' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="w10"> {{element.fecha | date : 'dd-MM-yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="expediente">
                <th mat-header-cell *matHeaderCellDef>
                    {{'FORM.EXPEDIENTE' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10"> {{ element.expediente }} </td>
            </ng-container>
            <ng-container matColumnDef="empleado">
                <th mat-header-cell *matHeaderCellDef>
                    {{'FORM.EMPLEADO' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w20"> {{ element.empleado }} </td>
            </ng-container>
            <ng-container matColumnDef="ayuda">
                <th mat-header-cell *matHeaderCellDef>
                    {{'FORM.AYUDA' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.descTipoAyuda }} </td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef>
                    {{'FORM.BENEFICIARIO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w20"> {{ element.beneficiario }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE_AYUDA' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.importe | number:'1.2-2' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3"> {{element.observaciones }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
    <div class="row pt-2">
        <div class="col-12">
            <button type="button" class="btn btn-danger float-end" (click)="confirmarDenegar()" [disabled]="dataSource.data.length == 0">
                <fa-icon [icon]="faTimes"></fa-icon> {{ 'BOTONES.DENEGAR' | translate }}
            </button>
            <button type="button" class="btn btn-success float-end me-1" (click)="aprobar()" [disabled]="dataSource.data.length == 0">
                <fa-icon [icon]="faTimes"></fa-icon> {{ 'BOTONES.APROBAR' | translate }}
            </button>
        </div>
    </div>
</div>