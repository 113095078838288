import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ayuda } from '../model/ayuda';
import { LabelValue } from '../model/labelvalue';

@Injectable({
  providedIn: 'root'
})
export class CierresService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getMesCierre() : Observable<number> {
    return this.httpClient.get<number>(`${this.URL}api/Cierres/mes`);
  }

  getAyudasAprobar() : Observable<Ayuda[]> {
    return this.httpClient.get<Ayuda[]>(`${this.URL}api/Cierres/aprobacion`);
  }

  cerrarMes(mes: number): Observable<boolean> {
    console.log(mes);
    return this.httpClient.post<boolean>(`${this.URL}api/Cierres`, mes);
  }

  confirmarCierre(aprobar: boolean, data: LabelValue): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.URL}api/Cierres/${aprobar}`, data);
  }
}
