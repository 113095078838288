import { Component, Inject, OnInit, isDevMode } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Autenticacion, AutenticacionErrorCode } from 'src/app/model/autenticacion';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error: boolean = false;
  mensajeError: string = "";
  loginForm;
  public logoUrl: string;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(DOCUMENT) private document: Document, private loginService: LoginService, private formBuilder: UntypedFormBuilder, private msalService: MsalService) {
    this.loginForm = this.formBuilder.group({
      usuar: '',
      lpassw: ''
    });
  }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe();
    this.logoUrl = `${environment.serverUrl}images/AlcoaLogo.png`;
  }

  login(userData) {
    console.log("Login: " + userData.usuar);
    this.loginService.login(userData.usuar, userData.lpassw, '409E3803-86AA-42F1-AAA2-174F254AFBF2').subscribe(resp => {
      if (resp.error == 0) {
        let rolesUsuario = resp.tokensPlanta[0].rol.split(",");
        if (rolesUsuario.length == 1 && rolesUsuario.includes("usuario")) {
          sessionStorage.setItem("plantasAccesoAtenciones", JSON.stringify(resp.tokensPlanta));
          sessionStorage.setItem("atencionesSocialesToken", resp.tokensPlanta[0].token);
          sessionStorage.setItem("idUsuarioAtenciones", resp.id.toString());
          sessionStorage.setItem("loginAtenciones", userData.usuar);
        } else {
          localStorage.setItem("plantasAccesoAtenciones", JSON.stringify(resp.tokensPlanta));
          localStorage.setItem("atencionesSocialesToken", resp.tokensPlanta[0].token);
          localStorage.setItem("idUsuarioAtenciones", resp.id.toString());
          localStorage.setItem("loginAtenciones", userData.usuar);
        }
        this.document.location.reload();
      } else {
        this.error = true;
        switch (resp.error) {
          case -1:
            this.mensajeError = "Contraseña Incorrecta";
            break;
          case -2:
            this.mensajeError = "No tiene permisos para acceder a la aplicación.";
            break;
          default:
            this.mensajeError = "Error desconocido. Contacte con I.T.";
            break;
        }
      }
    });
  }

  async ssoLogin() {
    const result = await this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as RedirectRequest).subscribe({
      next: result => {
        if (result != undefined) {
          this.loginService.loginSSO({
            appGuid: "409E3803-86AA-42F1-AAA2-174F254AFBF2",
            username: result.idTokenClaims["preferred_username"],
            issuedAt: new Date(result.idTokenClaims["iat"] * 1000),
            notBefore: new Date(result.idTokenClaims["nbf"] * 1000),
            expiredAt: new Date(result.idTokenClaims["exp"] * 1000)
          }).subscribe({
            next: resp => {
              if (resp.error == AutenticacionErrorCode.NoError) {
                let rolesUsuario = resp.tokensPlanta[0].rol.split(",");
                if (rolesUsuario.length == 1 && rolesUsuario.includes("usuario")) {
                  sessionStorage.setItem("plantasAccesoAtenciones", JSON.stringify(resp.tokensPlanta));
                  sessionStorage.setItem("atencionesSocialesToken", resp.tokensPlanta[0].token);
                  sessionStorage.setItem("idUsuarioAtenciones", resp.id.toString());
                  sessionStorage.setItem("loginAtenciones", result.account.name);
                } else {
                  localStorage.setItem("plantasAccesoAtenciones", JSON.stringify(resp.tokensPlanta));
                  localStorage.setItem("atencionesSocialesToken", resp.tokensPlanta[0].token);
                  localStorage.setItem("idUsuarioAtenciones", resp.id.toString());
                  localStorage.setItem("loginAtenciones", result.account.name);
                }
                this.document.location.reload();
              }
              else {
                switch (resp.error) {
                  case AutenticacionErrorCode.InvalidCredentials:
                    this.mensajeError = 'login.error.invalidcredentials';
                    break;
                  case AutenticacionErrorCode.NotAuthorized:
                    this.mensajeError = 'login.error.notauthorized';
                    break;
                  default:
                    this.mensajeError = 'login.error.generic';
                    break;
                }
                this.error = true;
              }
            }
          });
        }
      }
    });
  }

  esDev(): boolean {
    return isDevMode();
  }
}
