import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEraser, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Aviso } from 'src/app/model/aviso';
import { Empleado } from 'src/app/model/empleado';
import { PersonalService } from 'src/app/services/personal.service';
import { UsuariosdialogComponent } from '../../ayudas/ayuda/usuariosdialog/usuariosdialog.component';
import { AvisodialogComponent } from '../../dialog/avisodialog/avisodialog.component';

@Component({
  selector: 'app-buscadorempleado',
  templateUrl: './buscadorempleado.component.html',
  styleUrls: ['./buscadorempleado.component.css']
})
export class BuscadorempleadoComponent implements OnInit {
  faSearch = faSearch;
  faEraser = faEraser;

  expediente: number;
  empleado: Empleado = new Empleado();

  @Input() verLimpiar: boolean = true;
  @Input() idUsuario: number;

  @Output() newItemEvent = new EventEmitter<Empleado>();

  constructor(private personalService: PersonalService, public dialog: MatDialog) { }

  ngOnInit(): void {
    let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");
    let plantaUsuario: TokenPlanta = JSON.parse(ps);
    let rolesUsuario: string[] = plantaUsuario.rol.split(",");
    // Si es el rol "usuario" tiene que rellenar con su expediente y nombre
    console.log(this.idUsuario);
    if (this.idUsuario) {
      this.personalService.getEmpleadoById(this.idUsuario).subscribe(result => {
        this.empleado = result;
        this.expediente = Number.parseInt(this.empleado.expediente);
        this.newItemEvent.emit(this.empleado);
      }
      );
    } else if (rolesUsuario.length == 1 && rolesUsuario.includes("usuario")) {
      // Quitar la columan importe de la tabla
      let idUsuario = localStorage.getItem("idUsuarioAtenciones") ? localStorage.getItem("idUsuarioAtenciones") : sessionStorage.getItem("idUsuarioAtenciones");
      this.personalService.getEmpleadoById(Number.parseInt(idUsuario)).subscribe(result => {
        this.empleado = result;
        this.expediente = Number.parseInt(this.empleado.expediente);
        this.newItemEvent.emit(this.empleado);
      });
    }
  }

  buscarEmpleado(): void {
    if (this.expediente != null) {
      this.personalService.getEmpleadoByExpediente(this.expediente).subscribe(result => {
        this.seleccionarEmpleado(result);
      }, error => {
        console.error(error);
      });
    } else if (this.empleado.nombre != null) {
      this.personalService.getEmpleadoByNombre(this.empleado.nombre).subscribe(result => {
        this.seleccionarEmpleado(result);
      }, error => {
        console.error(error);
      });
    }
  }

  private seleccionarEmpleado(result: Empleado[]) {
    if (result.length == 1) {
      // Convertir expediente en entero
      this.empleado = result[0];
      this.expediente = Number.parseInt(this.empleado.expediente);
      this.newItemEvent.emit(this.empleado);
    } else if (result.length > 1) {
      let dialogRef = this.dialog.open(UsuariosdialogComponent, {
        width: "800px",
        disableClose: true,
        data: result
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.empleado = result;
          this.expediente = Number.parseInt(result.expediente);
          this.newItemEvent.emit(this.empleado);
        } else {
          this.empleado = new Empleado();
          this.newItemEvent.emit(this.empleado);
        }
      });
    } else {
      let aviso: Aviso = new Aviso();
      aviso.icono = faExclamationTriangle;
      aviso.claseIcono = "text-warning";
      aviso.titulo = "Atención";
      aviso.mensaje = `No se ha encontrado ningún empleado`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        disableClose: true,
        data: aviso
      });
    }
  }

  limpiarUsuario(): void {
    this.expediente = null;
    this.empleado = new Empleado();
    this.newItemEvent.emit(this.empleado);
  }
}
