<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Revision Solicitudes de Campamentos</h1>
    </div>
    <div class="row mt-3" [formGroup]="form">
        <mat-label class="col-1 col-form-label col-form-label-sm fw-bold">Año</mat-label>
        <div class="col-3">
            <input matInput class="form-control form-control-sm" name="fecha" placeholder="YYYY"
                [matDatepicker]="datePicker" formControlName="date" (click)="datePicker?.open()" />
            <mat-datepicker #datePicker color="primary" startView="multi-year"
                (yearSelected)="onYearSelected($event, datePicker)" startAt="2018"></mat-datepicker>
            <mat-error></mat-error>
        </div>
        <div class="col-2">
            <mat-slide-toggle color="primary" [(ngModel)]="modificarImporte" name="modificar" [ngModelOptions]="{standalone: true}"><span class="text-alcoa">Modificar importe</span></mat-slide-toggle>
        </div>
        <div class="col-3" *ngIf="modificarImporte">
            <input type="number" class="form-control form-control-sm" name="importe" [(ngModel)]="cantidad" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="col-1" *ngIf="modificarImporte">
            <button class="btn btn-sm btn-alcoa" (click)="guardar()"><fa-icon [icon]="faSave"></fa-icon></button>
        </div>
    </div>
    <div class="pt-3" style="width:100%; overflow: auto">
        <div class="row border-bottom border-alcoa">
            <h2 class="col-12">Solicitudes año</h2>
        </div>
        <table mat-table [dataSource]="dataSource" matSort
            class="table table-sm table-striped table-bordered table-light text-alcoa align-middle pt-3">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w5"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{
                    element.idPresupuesto }} </td>
            </ng-container>
            <ng-container matColumnDef="contrato">
                <th mat-header-cell *matHeaderCellDef> {{'MODAL.TIPO_CONTRATO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="validar(element)">{{element.convenio}}</td>
            </ng-container>
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.NOMBRE' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{
                    element.empleado }} </td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w20"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.beneficiario
                    }} </td>
            </ng-container>
            <ng-container matColumnDef="ano">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.ANO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w20"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.fecha | date
                    : 'yyyy' }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.importe |
                    currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{
                    element.observaciones }} </td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10"
                    (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.estado |
                    translate }}
                    <fa-icon [icon]="getIconoEstado(element)" [ngClass]="getClaseEstado(element)"></fa-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button *ngIf="(element.idEstado == 0 || (element.idEstado == 1 && !esUsuario))"
                        class="btn btn-sm btn-danger " style="font-size:10px;"
                        (click)="confirmarBorrarSolicitud(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"
                [ngClass]="{ 'selected': row === selectedRow, 'denegado': row.idEstado == 2 }"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>