import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Empleado } from '../model/empleado';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getEmpleadoByExpediente(expediente: number): Observable<Empleado[]> {
    return this.httpClient.get<Empleado[]>(`${this.URL}api/personal/exp/${expediente}`);
  }

  getEmpleadoByNombre(nombre: string): Observable<Empleado[]> {
    return this.httpClient.get<Empleado[]>(`${this.URL}api/personal/nombre/${nombre}`);
  }

  getEmpleadoById(idUsuario: number): Observable<Empleado> {
    return this.httpClient.get<Empleado>(`${this.URL}api/personal/${idUsuario}`);
  }

  getEmpleadosAyudasARevisar(): Observable<Empleado[]> {
    return this.httpClient.get<Empleado[]>(`${this.URL}api/personal/revisar`);
  }
}
