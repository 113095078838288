import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { faCheckCircle, faEraser, faExclamationTriangle, faPlus, faSave, faSearch, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Aviso } from 'src/app/model/aviso';
import { Beneficiario } from 'src/app/model/beneficiario';
import { Empleado } from 'src/app/model/empleado';
import { LabelValue } from 'src/app/model/labelvalue';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { PersonalService } from 'src/app/services/personal.service';
import { AvisodialogComponent } from '../../dialog/avisodialog/avisodialog.component';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';

@Component({
  selector: 'app-beneficiario',
  templateUrl: './beneficiario.component.html',
  styleUrls: ['./beneficiario.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BeneficiarioComponent implements OnInit {
  faTimes = faTimes;
  faSearch = faSearch;
  faEraser = faEraser;
  faPlus = faPlus;
  faSave = faSave;

  fechaNac = new UntypedFormControl(moment());
  date = new UntypedFormControl(moment());

  expediente: number;
  empleado: Empleado = new Empleado();
  beneficiario: Beneficiario = new Beneficiario();
  parentescos: LabelValue[] = [];
  valido: boolean = true;

  // Tabla
  dataSource: MatTableDataSource<Beneficiario>;
  displayColumns: string[] = ['nombre', 'apellidos', 'parentesco', 'fechaNacimiento', 'edad', 'beneficiario', 'borrar'];

  constructor(private personalService: PersonalService, private beneficiarioService: BeneficiarioService, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
    this.beneficiario.parentesco = new LabelValue();
    this.dataSource = new MatTableDataSource([]);
    this.buscarParentescos();
    this.fechaNac.setValue(null);
    this.date.setValue(null);
  }

  buscarParentescos() {
    this.beneficiarioService.getParentescos().subscribe(result => {
      this.parentescos = result;
    }, error => console.error(error)
    );
  }

  buscarEmpleado(empleado: Empleado): void {
    this.empleado = empleado;
    this.buscarBeneficiario();
  }

  buscarBeneficiario(): void {
    this.beneficiarioService.getBeneficiarios(this.empleado.idUsuario).subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
    }, error => console.error(error));
  }

  seleccionarBeneficiario(benef: Beneficiario): void {
    this.beneficiario = benef;
    this.fechaNac.setValue(benef.fechaNacimiento);
    this.date.setValue(benef.fechaBeneficiario);
  }

  validar(): boolean {
    this.valido = true;
    if (!this.beneficiario.nombre || this.beneficiario.nombre.length == 0) {
      console.log("nombre");
      this.valido = false;
    }
    if (!this.beneficiario.apellidos || this.beneficiario.apellidos.length == 0) {
      console.log("apellidos");
      this.valido = false;
    }
    if (this.beneficiario.parentesco.value == undefined) {
      console.log("parentesco");
      this.valido = false;
    }
    if (!this.fechaNac.value) {
      console.log("fecha");
      this.valido = false;
    }

    return this.valido;
  }

  guardarBeneficiario(): void {
    if (this.validar()) {
      this.beneficiario.idUsuario = this.empleado.idUsuario;
      this.beneficiario.fechaNacimiento = this.fechaNac.value;
      this.beneficiario.fechaBeneficiario = this.date.value;
      if (this.beneficiario.idBeneficiario == null) {
        this.beneficiarioService.altaBeneficiario(this.beneficiario).subscribe(result => {
          if (result) {
            this.limpiarFormulario();
            this.buscarBeneficiario();
            let aviso: Aviso = new Aviso();
            aviso.icono = faCheckCircle;
            aviso.claseIcono = "text-success";
            aviso.titulo = "Atención";
            aviso.mensaje = `Se ha insertado el nuevo beneficiario`;
            const dialogRef = this.dialog.open(AvisodialogComponent, {
              width: "400px",
              data: aviso
            });
          }
        });
      } else {
        this.beneficiarioService.modificaBeneficiario(this.beneficiario).subscribe(result => {
          if (result) {
            this.limpiarFormulario();
            this.buscarBeneficiario();
            let aviso: Aviso = new Aviso();
            aviso.icono = faCheckCircle;
            aviso.claseIcono = "text-success";
            aviso.titulo = "Atención";
            aviso.mensaje = `Se ha modificado el beneficiario`;
            const dialogRef = this.dialog.open(AvisodialogComponent, {
              width: "400px",
              data: aviso
            });
          }
        });
      }
    }
  }

  confirmarBorrarBeneficiario(beneficiario: Beneficiario) {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a eliminar el beneficiario ${beneficiario.nombre} ${beneficiario.apellidos}. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.borrarBeneficiario(beneficiario);
      }
    });
  }

  borrarBeneficiario(beneficiario: Beneficiario): void {
    this.beneficiarioService.bajaBeneficiario(beneficiario.idBeneficiario).subscribe(result => {
      if (result) {
        this.limpiarFormulario();
        this.buscarBeneficiario();
      }
    });
  }

  limpiarFormulario(): void {
    this.beneficiario = new Beneficiario();
    this.beneficiario.parentesco = new LabelValue();
    this.fechaNac.setValue(null);
    this.date.setValue(null);
  }
}

