import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faSearch, faEraser, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { LabelValue } from 'src/app/model/labelvalue';
import { Presupuesto } from 'src/app/model/presupuesto';
import { AyudasService } from 'src/app/services/ayudas.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-informepresupuestos',
  templateUrl: './informepresupuestos.component.html',
  styleUrls: ['./informepresupuestos.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class InformepresupuestosComponent implements OnInit {
  faSearch = faSearch;
  faEraser = faEraser;
  faExcel = faFileExcel;

  tipoConvenio: number;
  tipoAyuda: number[];
  expediente: number;
  nombre: string;
  estados: LabelValue[] = [];
  estado: number;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  // Tabla
  dataSource: MatTableDataSource<Presupuesto>;
  displayColumns: string[] = ['fecha', 'ayuda', 'contrato', 'expediente', 'empleado', 'beneficiario', 'fechaNac', 'importe', 'observaciones', 'estado'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService, private presupuestosService: PresupuestosService, private datePipe: DatePipe, private translate: TranslateService) { }

  ngOnInit(): void {
    this.presupuestosService.getEstadosPresupuestos().subscribe(result => {
      this.estados = result;
    }, error => console.error(error));
  }

  buscar(): void {
    this.ayudasService.getPresupuestosInforme(this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd'),
    this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd'), this.estado, this.tipoAyuda, this.expediente, this.nombre).subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  limpiar(): void {
    this.range.value.start = "";
    this.range.value.end = "";
    this.nombre = "";
  }

  exportar(): void {
    if (this.dataSource != undefined) {
      var prepare = [];
      this.dataSource.data.forEach(a => {
        var tempObj = [];
        tempObj.push(this.datePipe.transform(a.fecha, "dd-MM-yyyy"));
        tempObj.push(a.descTipoAyuda);
        tempObj.push(a.convenio);
        tempObj.push(a.expediente);
        tempObj.push(a.empleado);
        tempObj.push(a.beneficiario);
        tempObj.push(a.fechaNac);
        tempObj.push(a.importe);
        tempObj.push(a.observaciones);
        tempObj.push(this.translate.instant(a.estado));
        prepare.push(tempObj);
      });
      const workSheet = XLSX.utils.json_to_sheet([]);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();

      //Had to create a new workbook and then add the header
      var Heading = [['Fecha', 'Ayuda', 'Tipo Contrato', 'Expediente', 'Empleado', 'Beneficiario', 'Fecha Nac.', 'Importe', 'Observaciones', 'Estado']];
      XLSX.utils.sheet_add_aoa(workSheet, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, prepare, { origin: 'A2', skipHeader: true });

      XLSX.utils.book_append_sheet(workBook, workSheet, 'InformePresupuestos');
      XLSX.writeFile(workBook, 'InformePresupuestos.xlsx');
    }
  }

}
