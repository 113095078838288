import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faCheck, faCheckCircle, faExclamationTriangle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Aviso } from 'src/app/model/aviso';
import { Ayuda } from 'src/app/model/ayuda';
import { LabelValue } from 'src/app/model/labelvalue';
import { CierresService } from 'src/app/services/cierres.service';
import { AvisodialogComponent } from '../../dialog/avisodialog/avisodialog.component';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { DenegardialogComponent } from './denegardialog/denegardialog.component';

@Component({
  selector: 'app-aprobacionhr',
  templateUrl: './aprobacionhr.component.html',
  styleUrls: ['./aprobacionhr.component.css']
})
export class AprobacionhrComponent implements OnInit {
  faCheck = faCheck;
  faTimes = faTimes;

  // Tabla
  dataSource: MatTableDataSource<Ayuda>;
  displayColumns: string[] = ['fecha', 'expediente', 'empleado', 'ayuda', 'beneficiario', 'importe', 'observaciones'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cierresService: CierresService, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource([]);
   }

  ngOnInit(): void {
    this.buscar();
  }

  buscar(): void {
    this.cierresService.getAyudasAprobar().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => console.error(error));
  }

  aprobar(): void {
    let datos: LabelValue = new LabelValue();
    let ayudaAux: Ayuda = this.dataSource.data[0];
    datos.value =ayudaAux.mesCierre;
    this.cierresService.confirmarCierre(true, datos).subscribe(result => {
      let aviso: Aviso = new Aviso();
      aviso.titulo = "Aprobado";
      aviso.icono = faCheckCircle;
      aviso.claseIcono = "text-success";
      aviso.mensaje = `Se ha Aprobado el cierre del mes`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        this.buscar();
      });
    }, error => console.error(error));
  }

  confirmarDenegar(): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a denegar el cierre. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(DenegardialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.denegar(result);
      }
    });
  }

  denegar(comentarios: string): void {
    let datos: LabelValue = new LabelValue();
    datos.value = this.dataSource.data[0].mesCierre;
    datos.label = comentarios;
    this.cierresService.confirmarCierre(false, datos).subscribe(result => {
      let aviso: Aviso = new Aviso();
      aviso.icono = faTimesCircle;
      aviso.titulo = "Denegado";
      aviso.claseIcono = "text-danger";
      aviso.mensaje = `Se ha denegado el cierre del mes`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        data: aviso
      });

    dialogRef.afterClosed().subscribe(result => {
      this.buscar();
    });
    }, error => console.error(error));
  }
}
