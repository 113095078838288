import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faSave, faEraser, faTimes, faPaperclip, faExclamationTriangle, IconDefinition, faQuestion, faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmaciondialogComponent } from 'src/app/components/dialog/confirmaciondialog/confirmaciondialog.component';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Aviso } from 'src/app/model/aviso';
import { Beneficiario } from 'src/app/model/beneficiario';
import { Empleado } from 'src/app/model/empleado';
import { Presupuesto } from 'src/app/model/presupuesto';
import { TipoAyudaContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { DenegardialogComponent } from '../../aprobacionhr/denegardialog/denegardialog.component';

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit {
  faSave = faSave;
  faEraser = faEraser;
  faTimes = faTimes;
  faPaperClip = faPaperclip;

  empleado: Empleado = new Empleado();
  presupuesto: Presupuesto = new Presupuesto();
  tipoAyudas: TipoAyudaContrato[] = [];
  beneficiarios: Beneficiario[] = [];
  valido: boolean = true;
  fileToUpload: File | null = null;
  tipoAyudaSel: TipoAyudaContrato;

  beneficiariosSel: Beneficiario[] = [];

  tipoAyudaCampamento: number[] = [2];
  esSolicitud: boolean = false;

  @Input() idUsuario: number;
  esRevision: boolean = false;
  esUsuario: boolean = false;

  // Tabla
  dataSource: MatTableDataSource<Presupuesto>;
  displayColumns: string[] = ['id', 'ayuda', 'beneficiario', 'ano', 'estado', 'observaciones', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Presupuesto;

  constructor(private ayudasService: AyudasService, private presupuestosService: PresupuestosService, private beneficiarioService: BeneficiarioService, private translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");

    let plantaUsuario: TokenPlanta = JSON.parse(ps);
    let rolesUsuario = plantaUsuario.rol.split(",");
    // Si es el rol "usuario" tiene que rellenar con su expediente y nombre
    if (this.idUsuario) {
      this.esRevision = true;
    } else if (rolesUsuario.includes("usuario")) {
      let id = localStorage.getItem("idUsuarioAtenciones") ? localStorage.getItem("idUsuarioAtenciones") : sessionStorage.getItem("idUsuarioAtenciones");
      this.idUsuario = Number.parseInt(id);
      this.esUsuario = true;
    }
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  buscarEmpleado(empleado: Empleado): void {
    this.limpiarPresupuesto();
    this.empleado = empleado;
    this.buscarBeneficiario();
  }

  buscarBeneficiario(): void {
    this.beneficiarioService.getBeneficiarios(this.empleado.idUsuario).subscribe({
      next: result => {
        this.beneficiarios = result;
        this.buscarTipoAyudas();
      },
      error: error => console.error(error)
    });
  }

  buscarTipoAyudas(): void {
    this.ayudasService.getTipoAyudas(this.empleado.idContractType).subscribe({
      next: result => {
        this.tipoAyudas = result;
        let ayudasCampamentos: TipoAyudaContrato[] = this.tipoAyudas.filter(ta => this.tipoAyudaCampamento.includes(ta.idTipoAyuda));
        this.tipoAyudaSel = ayudasCampamentos[0];
        this.seleccionTipo();
        this.buscarPresupuestos();
      },
      error: error => console.error(error)
    });
  }

  buscarPresupuestos(): void {
    this.presupuestosService.getPresupuestosUsuario(this.empleado.idUsuario).subscribe(result => {
      let ano: number = new Date().getFullYear();
      let aux: Presupuesto[] = result.filter(p => p.idTipoAyuda == this.tipoAyudaSel.idTipoAyudaContrato && new Date(p.fecha).getFullYear() == ano && [0,1,3].includes(p.idEstado));
      aux.forEach(p => {
        if (this.beneficiarios.some(b => b.idBeneficiario == p.idBeneficiario)) {
          let b = this.beneficiarios.filter(b => b.idBeneficiario == p.idBeneficiario)[0];
          this.beneficiarios.splice(this.beneficiarios.indexOf(b), 1);
        }
      });

      if (this.esUsuario) {
        this.dataSource.data = result.filter(p => p.idEstado != 4 && p.idTipoAyuda == this.tipoAyudaSel.idTipoAyudaContrato);
      } else {
        this.dataSource.data = result.filter(p => p.idTipoAyuda == this.tipoAyudaSel.idTipoAyudaContrato);
      }
      this.dataSource = this.dataSource;
    });
  }

  limpiarPresupuesto(): void {
    this.presupuesto = new Presupuesto();
    this.beneficiariosSel = [];
  }

  validar(): boolean {
    this.valido = true;
    if (!this.presupuesto.idTipoAyuda) {
      this.valido = false;
    }
    if (this.esSolicitud && this.beneficiariosSel.length == 0) {
      this.valido = false;
    }

    return this.valido;
  }

  altaPresupuesto(): void {
    if (this.validar()) {
      this.presupuesto.idUsuario = this.empleado.idUsuario;
      this.presupuesto.idEstado = 1;
      this.presupuesto.importe = 0;
      // Se da de alta 1 por cada beneficiario selecionado
      this.beneficiariosSel.forEach(b => {
        let auxPre: string = JSON.stringify(this.presupuesto);
        let nuevo: Presupuesto = JSON.parse(auxPre);
        nuevo.idBeneficiario = b.idBeneficiario;
        this.presupuestosService.altaPresupuesto(nuevo).subscribe(result => {
          if (result.idPresupuesto) {
            this.limpiarPresupuesto();
            this.buscarPresupuestos();
          }
        });
      });
    }
  }

  public getIconoEstado(presupuesto: Presupuesto): IconDefinition {
    let icono: IconDefinition;
    switch (presupuesto.idEstado) {
      case 0:
        icono = faQuestion;
        break;
      case 1:
        icono = faCheck;
        break;
      case 2:
        icono = faTimes;
        break;
      case 3:
        icono = faCheckDouble;
        break;
      default:
        break;
    }

    return icono;
  }

  public getClaseEstado(presupuesto: Presupuesto): string {
    if (presupuesto.idEstado == 0) {
      return "text-warning";
    } else if (presupuesto.idEstado == 1) {
      return "text-success";
    } else if (presupuesto.idEstado == 2) {
      return "text-danger";
    } else {
      return "";
    }
  }

  seleccionTipo(): void {
    if (this.tipoAyudaSel) {
      this.presupuesto.idTipoAyuda = this.tipoAyudaSel.idTipoAyudaContrato;
      this.esSolicitud = true;
      let anoIni = new Date().getFullYear() - 17;
      let anoFin = new Date().getFullYear() - 6;
      let fechaIni: Date = new Date(anoIni, 5, 1, 0, 0, 0);
      let fechaFin = new Date(anoFin, 5, 1, 0, 0, 0);
      this.beneficiarios = this.beneficiarios.filter(b => b.parentesco.value == 2 && new Date(b.fechaNacimiento).getTime() >= fechaIni.getTime() && new Date(b.fechaNacimiento).getTime() < fechaFin.getTime());
    }
  }

  compareTipo(tac1: TipoAyudaContrato, tac2: TipoAyudaContrato): boolean {
    return tac1 && tac2 ? tac1.idTipoAyudaContrato === tac2.idTipoAyudaContrato : tac1 === tac2;
  }

}
