<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">{{ 'AYUDAS.TIPO_AYUDAS_TITULO' | translate }}</h1>
    </div>
    <div class="row pt-2">
        <div class="col-12">
            <button type="button" class="btn btn-alcoa float-end" data-toggle="modal" data-placement="top"
                title="Nuevo tipo de ayuda" (click)="nuevoTipoAyuda()"><fa-icon [icon]="faPlusSquare"></fa-icon> {{ 'BOTONES.NUEVO_TIPO_AYUDA' | translate }}</button>
        </div>
    </div>
    <div class="pt-2" style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort
            class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'MODAL.TIPO_AYUDA' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" (click)="modificarTipoAyuda(element)"> {{element.descripcion}} </td>
            </ng-container>
            <ng-container matColumnDef="tipoContrato">
                <th mat-header-cell *matHeaderCellDef>
                    {{'MODAL.TIPO_CONTRATO' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="modificarTipoAyuda(element)"> {{element.contrato }} </td>
            </ng-container>
            <ng-container matColumnDef="exenta">
                <th mat-header-cell *matHeaderCellDef>
                    {{'MODAL.EXENTA' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="modificarTipoAyuda(element)"> {{
                    element.exentaIRPF ? "Si" : "No" }} </td>
            </ng-container>            
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.mesCierre" class="btn btn-danger btn-sm float-end" style="font-size:10px;"
                        (click)="confirmarBorrar(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>