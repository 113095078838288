import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faExclamationTriangle, faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Aviso } from 'src/app/model/aviso';
import { TipoAyudaConContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { NuevotipoayudadialogComponent } from './nuevotipoayudadialog/nuevotipoayudadialog.component';

@Component({
  selector: 'app-tipoayudas',
  templateUrl: './tipoayudas.component.html',
  styleUrls: ['./tipoayudas.component.css']
})
export class TipoayudasComponent implements OnInit {
  faTimes = faTimes;
  faPlusSquare = faPlusSquare;

  // Tabla
  dataSource: MatTableDataSource<TipoAyudaConContrato>;
  displayColumns: string[] = ['descripcion', 'tipoContrato', 'exenta', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buscarTipoAyudas();
  }

  buscarTipoAyudas(): void {
    this.ayudasService.getTipoAyudasConContrato().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  nuevoTipoAyuda(): void {
    const dialogRef = this.dialog.open(NuevotipoayudadialogComponent, {
      width: "800px",
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
        this.buscarTipoAyudas();
    });
  }

  modificarTipoAyuda(tipoAyuda: TipoAyudaConContrato): void {
    const dialogRef = this.dialog.open(NuevotipoayudadialogComponent, {
      width: "800px",
      data: tipoAyuda,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarTipoAyudas();
  });
  }

  confirmarBorrar(tipoAyuda: TipoAyudaConContrato): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a eliminar el tipo de ayuda ${tipoAyuda.descripcion}. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.borrar(tipoAyuda);
        this.buscarTipoAyudas();
      }
    });
  }

  borrar(tipoAyuda: TipoAyudaConContrato): void {
    this.ayudasService.borraTipoAyudas(tipoAyuda.idTipoAyuda).subscribe(result => {
      if (result) {
        this.buscarTipoAyudas();
      }
    });
  }
}
