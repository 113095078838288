import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheck, faDoorClosed, faPencil } from '@fortawesome/free-solid-svg-icons';
import { LabelValue } from 'src/app/model/labelvalue';
import { TipoAyudaConContrato, TipoAyudaContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';

@Component({
  selector: 'app-nuevotipoayudadialog',
  templateUrl: './nuevotipoayudadialog.component.html',
  styleUrls: ['./nuevotipoayudadialog.component.css']
})
export class NuevotipoayudadialogComponent implements OnInit {
  faCheck = faCheck;
  faDoorClosed = faDoorClosed;
  faPencil = faPencil;

  tiposAyuda: TipoAyudaConContrato[] = [];
  tipoAyudaSel: TipoAyudaConContrato;
  tipoAyuda: TipoAyudaConContrato = new TipoAyudaConContrato();
  tipoContratos: LabelValue[];
  valido: boolean = true;
  incluidos: number = 0;
  editar: boolean = false;

  constructor(public dialogRef: MatDialogRef<NuevotipoayudadialogComponent>, private ayudasService: AyudasService, @Inject(MAT_DIALOG_DATA) public data: TipoAyudaConContrato) { }

  ngOnInit(): void {
    this.ayudasService.getTipoAyudasSinContrato().subscribe({
      next: result => {
        this.tiposAyuda = result;
      }
    });

    this.ayudasService.getTipoContratos().subscribe({
      next: result => {
        let tipoContratosAyuda: TipoAyudaContrato[] = [];
        result.forEach(tc => {
          let tipo = new TipoAyudaContrato();
          if (this.data && this.data.tiposContrato.some(tac => tac.idContractType == tc.value)) {
            tipo = this.data.tiposContrato.filter(tac => tac.idContractType == tc.value)[0];
            tipo.incluido = true;
          } else {
            tipo.idContractType = tc.value;
            tipo.descripcion = tc.label;
            tipo.incluido = false;
          }
          tipoContratosAyuda.push(tipo);
        });
        this.tipoContratos = result;

        this.tipoAyuda.tiposContrato = tipoContratosAyuda;
        if (this.data) {
          this.tipoAyuda.exentaIRPF = this.data.exentaIRPF ? this.data.exentaIRPF : false;
          this.tipoAyuda.descripcion = this.data.descripcion;
          this.tipoAyuda.contrato = this.data.contrato;
          this.tipoAyuda.idTipoAyuda = this.data.idTipoAyuda;
        } else {
          this.tipoAyuda.exentaIRPF = true;
        }
      },
      error: error => console.error(error)
    });
  }

  validar(): boolean {
    this.valido = true;
    if (!this.editar && this.tipoAyudaSel) {
      this.tipoAyuda.descripcion = this.tipoAyudaSel.descripcion;
      this.tipoAyuda.idTipoAyuda = this.tipoAyudaSel.idTipoAyuda;
    }
    if (!this.tipoAyuda.descripcion || this.tipoAyuda.descripcion.length == 0) {
      this.valido = false;
    }
    if (this.tipoAyuda.exentaIRPF == undefined) {
      this.valido = false;
    }
    this.incluidos = 0;
    this.tipoAyuda.tiposContrato.forEach(tc => {
      if (tc.incluido) {
        this.incluidos++;
        if (!tc.codigoNomina || tc.codigoNomina.length == 0) {
          this.valido = false;
        }
      }
    });
    if (this.incluidos == 0) {
      this.valido = false;
    }

    return this.valido;
  }

  guardar(): void {
    if (this.validar()) {
      let ta: TipoAyudaConContrato = JSON.parse(JSON.stringify(this.tipoAyuda));
      ta.tiposContrato = this.tipoAyuda.tiposContrato.filter(tc => tc.incluido == true);
      if (ta.idTipoAyuda) {
        this.ayudasService.altaTipoAyudas(ta).subscribe({
          next: result => {
            this.dialogRef.close();
          },
          error: error => console.error(error)
        });
      } else {
        this.ayudasService.modificaTipoAyudas(ta).subscribe({
          next: result => {
            this.dialogRef.close();
          },
          error: error => console.error(error)
        });
      }
    } else {
      console.log("Error validar");
    }
  }

}
