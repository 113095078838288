<div class="container text-alcoa">
  <div class="row border-bottom border-alcoa">
    <h1 class="col-12 text-alcoa">Solicitud de Campamentos</h1>
  </div>
  <div class="row pt-2">
    <app-buscadorempleado (newItemEvent)="buscarEmpleado($event)" [verLimpiar]="!esUsuario && !esRevision"
      [idUsuario]="idUsuario"></app-buscadorempleado>
  </div>
  <form id="ayudasFrm" name="ayudasFrm" class="needs-validation pt-3" novalidate *ngIf="empleado.idUsuario">
    <div class="row border-bottom border-alcoa">
      <h2 class="col-12">Nueva solicitud</h2>
    </div>
    <div class="form-group row pt-1">
      <label class="col-lg-2 col-md-3 col-4 col-form-label col-form-label-sm fw-bold" for="tiposAyuda"
        id="lblAyuda">{{ 'FORM.AYUDA' | translate }}:</label>
      <div class="col-lg-3 col-md-9 col-8">
        <select class="form-control form-control-sm" id="tiposAyuda" name="tiposAyuda" [(ngModel)]="tipoAyudaSel"
          [ngClass]="!valido && !presupuesto.idTipoAyuda ? 'obligatorio' : '' " (ngModelChange)="seleccionTipo()"
          [compareWith]="compareTipo" disabled>
          <option selected></option>
          <option [ngValue]="tipo" *ngFor="let tipo of tipoAyudas">{{ tipo.descripcion }}</option>
        </select>
      </div>
      <label class="col-lg-2 col-md-3 col-4 mt-lg-0 mt-1 col-form-label col-form-label-sm fw-bold" for="beneficiario"
        id="lblBeneficiario">{{ 'FORM.BENEFICIARIO' | translate }}:</label>
      <div class="col-lg-5 col-md-9 col-8 mt-lg-0 mt-1">
        <mat-select multiple class="form-control form-control-sm" id="beneficiario" #beneficiario="ngModel"
          name="beneficiario" [(ngModel)]="beneficiariosSel"
          [ngClass]="!valido && beneficiario.hasError('required') ? 'obligatorio' : '' "
          [disabled]="!presupuesto.idTipoAyuda || !beneficiarios" required>
          <mat-option [value]="beneficiario" *ngFor="let beneficiario of beneficiarios">{{ beneficiario.nombre }}
            {{beneficiario.apellidos}}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <button type="button" class="btn btn-alcoa float-end ms-2" (click)="altaPresupuesto()">
          <fa-icon [icon]="faSave"></fa-icon> {{ 'BOTONES.GUARDAR' | translate }}
        </button>
      </div>
    </div>
  </form>
  <div class="pt-3" style="width:100%; overflow: auto" [hidden]="!empleado.idUsuario">
    <div class="row border-bottom border-alcoa">
      <h2 class="col-12">Histórico</h2>
    </div>
    <table mat-table [dataSource]="dataSource" matSort
      class="table table-sm table-striped table-bordered table-light text-alcoa align-middle pt-3">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id</th>
        <td mat-cell *matCellDef="let element" class="ps-3 w5"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.idPresupuesto }} </td>
      </ng-container>
      <ng-container matColumnDef="ayuda">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="ps-3 w15"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.descTipoAyuda }} </td>
      </ng-container>
      <ng-container matColumnDef="beneficiario">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="ps-3 w20"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.beneficiario }} </td>
      </ng-container>
      <ng-container matColumnDef="ano">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.ANO' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="ps-3 w20"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.fecha | date : 'yyyy' }} </td>
      </ng-container>
      <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="text-end pe-3"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.observaciones }} </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element" class="ps-3 w10"
          (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.estado | translate }}
          <fa-icon [icon]="getIconoEstado(element)" [ngClass]="getClaseEstado(element)"></fa-icon></td>
      </ng-container>
      <ng-container matColumnDef="borrar">
        <th mat-header-cell *matHeaderCellDef class="w5"></th>
        <td mat-cell *matCellDef="let element" class="w5 text-center">
          <button *ngIf="(element.idEstado == 0 || (element.idEstado == 1 && !esUsuario))"
            class="btn btn-sm btn-danger " style="font-size:10px;" (click)="confirmarBorrarPresupuesto(element)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"
        [ngClass]="{ 'selected': row === selectedRow, 'denegado': row.idEstado == 2 }"> </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
  </div>
</div>