import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Aviso } from 'src/app/model/aviso';

@Component({
  selector: 'app-confirmaciondialog',
  templateUrl: './confirmaciondialog.component.html',
  styleUrls: ['./confirmaciondialog.component.css']
})
export class ConfirmaciondialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmaciondialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Aviso) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

}
