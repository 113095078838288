<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Bajas a revisar</h1>
    </div>
    <div class="pt-2" style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort
            class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FORM.NOMBRE' | translate }} </th>
                <td mat-cell *matCellDef="let element" (click)="editar(element)"> {{element.usuario.nombre}} </td>
            </ng-container>  
            <ng-container matColumnDef="login">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'BUSCA_PERSONAL.LOGIN' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.usuario.login}} </td>
            </ng-container>  
            <ng-container matColumnDef="expediente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.EXPEDIENTE' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.usuario.expediente}} </td>
            </ng-container>  
            <ng-container matColumnDef="fechaIni">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.FECHA_INICIO' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.fechaInicio | date : 'mediumDate':'':translate.currentLang}} </td>
            </ng-container>   
            <ng-container matColumnDef="fechaFin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.FECHA_FIN' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.fechaFin | date : 'mediumDate':'':translate.currentLang}} </td>
            </ng-container>          
            <ng-container matColumnDef="adjunto">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button mat-icon-button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;"  [matMenuTriggerFor]="adjunto" *ngIf="element.incapacidadTemporalAdjuntos.length > 0">
                        <fa-icon [icon]="faPaperClip"></fa-icon>
                      </button>
                      <mat-menu #adjunto="matMenu">
                        <button mat-menu-item *ngFor="let file of element.incapacidadTemporalAdjuntos" (click)="verFichero(file.idIncapacidadTemporalAdjunto)" class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;">
                          <fa-icon [icon]="faFile"></fa-icon> <span>{{file.nombre}}</span>
                        </button>
                      </mat-menu>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef class="w10"></th>
                <td mat-cell *matCellDef="let element" class="w10 text-center p-0">
                    <button class="btn btn-sm btn-outline-success" style="font-size:10px;" (click)="aceptar(element)">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="btn btn-sm btn-outline-danger ms-2" style="font-size:10px;" (click)="confirmarBorrar(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{ 'selected': row === selectedRow}"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
