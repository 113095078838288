import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IncapacidadTemporal, IncapacidadTemporalAdjunto, ParteConfirmacion, TipoIncapacidadTemporal } from '../model/incapacidadtemporal';
import { TipoAyudaContrato } from '../model/tipoayuda';

@Injectable({
  providedIn: 'root'
})
export class BajasService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getBajasUsuario(idUsuario: number) : Observable<IncapacidadTemporal[]> {
    return this.httpClient.get<IncapacidadTemporal[]>(`${this.URL}api/Bajas/${idUsuario}`);
  }

  getBajasRevisar() : Observable<IncapacidadTemporal[]> {
    return this.httpClient.get<IncapacidadTemporal[]>(`${this.URL}api/Bajas/revisar`);
  }

  getBajasActivas() : Observable<IncapacidadTemporal[]> {
    return this.httpClient.get<IncapacidadTemporal[]>(`${this.URL}api/Bajas/activas`);
  }

  getBajasFormulario(parametros: HttpParams) : Observable<IncapacidadTemporal[]> {
    return this.httpClient.get<IncapacidadTemporal[]>(`${this.URL}api/Bajas`, {params: parametros});
  }

  getPartesConfirmacion(idIncapacidadTemporal: number) {
    return this.httpClient.get<ParteConfirmacion[]>(`${this.URL}api/Bajas/partes/${idIncapacidadTemporal}`);
  }

  addBajaUsuario(it: IncapacidadTemporal, fileToUpload: File): Observable<IncapacidadTemporal> {
    const formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('adjunto', fileToUpload, fileToUpload.name);
    }
    formData.append('baja', JSON.stringify(it));
    return this.httpClient.post<IncapacidadTemporal>(`${this.URL}api/Bajas/`, formData);
  }

  updateBajaUsuario(it: IncapacidadTemporal, fileToUpload: File): Observable<IncapacidadTemporal> {
    const formData: FormData = new FormData();
    if (fileToUpload != null) {
      formData.append('adjunto', fileToUpload, fileToUpload.name);
    }
    formData.append('baja', JSON.stringify(it));
    return this.httpClient.put<IncapacidadTemporal>(`${this.URL}api/Bajas/${it.idIncapacidadTemporal}`, formData);
  }

  deleteBajaUsuario(idIncapacidadTemporal: number): Observable<IncapacidadTemporal> {
    return this.httpClient.delete<IncapacidadTemporal>(`${this.URL}api/Bajas/${idIncapacidadTemporal}`);
  }

  getBajaIdsAdjunto(id: number) : Observable<IncapacidadTemporalAdjunto[]> {
    return this.httpClient.get<IncapacidadTemporalAdjunto[]>(`${this.URL}api/Bajas/adjuntos/${id}`);
  }

  getBajaAdjunto(id: number) : Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.URL}api/Bajas/adjunto/${id}`, { observe: 'body', responseType: 'blob' as 'json' });
  }

  altaBajaAdjunto(idIncapacidadTemporal: number, fileToUpload: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('adjunto', fileToUpload, fileToUpload.name);
    formData.append('idIncapacidadTemporal', JSON.stringify(idIncapacidadTemporal));
    return this.httpClient.post<number>(`${this.URL}api/Bajas/adjunto`, formData);
  }

  deleteBajaAdjunto(id: number): Observable<number> {
    return this.httpClient.delete<number>(`${this.URL}api/Bajas/adjuntos/${id}`);
  }

  getTipos() : Observable<TipoIncapacidadTemporal[]> {
    return this.httpClient.get<TipoIncapacidadTemporal[]>(`${this.URL}api/Bajas/tipos`);
  }

  addParteConfirmacion(it: IncapacidadTemporal, fileToUpload: File): Observable<IncapacidadTemporal> {
    const formData: FormData = new FormData();
    formData.append('adjunto', fileToUpload, fileToUpload.name);
    formData.append('baja', JSON.stringify(it));
    return this.httpClient.post<IncapacidadTemporal>(`${this.URL}api/Bajas/parte`, formData);
  }
}
