import { LabelValue } from "./labelvalue";

export class Beneficiario {
    idBeneficiario: number;
    idUsuario: number;
    nombre: string;
    apellidos: string;
    fechaNacimiento: Date;
    fechaBeneficiario: Date;
    parentesco: LabelValue;
    descripcion: string;
    edad: number;
    beneficiarioSS: boolean;
    edadBeneficiarioSS: number;
    estado: string;
}