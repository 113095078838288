<h2 mat-dialog-title class="text-alcoa border-bottom border-alcoa-gris">
    <fa-icon [ngClass]="data.claseIcono" [icon]="data.icono"></fa-icon> {{data.titulo}}
</h2>
<mat-dialog-content class="mat-typography text-alcoa pb-3">
    <div class="row">
        {{data.mensaje}}
    </div>
    <div class="row mt-3">
        <textarea class="col-12 form-control" rows="3" [(ngModel)]="comentario" [ngClass]="!valido && !comentario ? 'obligatorio' : '' "></textarea>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="border-top border-alcoa-gris">
  <button class="btn btn-success me-2" (click)="aceptar()">Aceptar</button>
    <button mat-dialog-close class="btn btn-secondary" cdkFocusInitial>Cancelar</button>
</mat-dialog-actions>
