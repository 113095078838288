<h2 mat-dialog-title class="text-alcoa border-bottom border-alcoa-gris">
  <fa-icon [ngClass]="data.claseIcono" [icon]="data.icono"></fa-icon> {{data.titulo}}
</h2>
<mat-dialog-content class="mat-typography text-alcoa pb-3">
    {{data.mensaje}}
</mat-dialog-content>
<mat-dialog-actions align="end" class="border-top border-alcoa-gris">
  <button mat-dialog-close class="btn btn-secondary" cdkFocusInitial>Cancelar</button>
  <button class="btn btn-success" [mat-dialog-close]="true">Aceptar</button>
</mat-dialog-actions>
