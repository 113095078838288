<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Incapacidad Temporal</h1>
    </div>
    <div class="row pt-2">
        <app-buscadorempleado (newItemEvent)="buscarEmpleado($event)" [verLimpiar]="!esUsuario && !esRevision" [idUsuario]="idUsuario"></app-buscadorempleado>
    </div>
    <form id="bajasFrm" name="bajasFrm" #bajasFrm="ngForm" (ngSubmit)="submitBaja(bajasFrm)" class="needs-validation pt-3" novalidate *ngIf="empleado.idUsuario">
        <div class="row border-bottom border-alcoa">
            <h2 class="col-12">Nueva I.T.</h2>
        </div>
        <div class="row">
            <div class="form-group row pt-1">
                <label id="lblTipo" for="lblTipo" class="col-lg-1 col-md-2 col-sm-4 col-form-label col-form-label-sm fw-bold">{{ 'FORM.TIPO_IT' | translate }}:</label>
                <div class="col-lg-2 col-md-10 col-sm-8">
                    <select class="form-control form-control-sm" id="tipoIncapacidad" name="tipoIncapacidad" [(ngModel)]="baja.idTipoIncapacidadTemporal" required
                        [disabled]="rolesUsuario.includes('servicio_medico') || bajaPendiente">
                        <option [ngValue]="tipo.idTipoIncapacidadTemporal" *ngFor="let tipo of tipos">{{ tipo.descripcion }}</option>
                    </select>
                </div>
                <div class="col-lg-7 col-12">
                    <div class="row">
                        <label id="lblFechaIni" for="fechaIni" class="col-lg-2 col-md-2 col-sm-4 align-self-center col-form-label col-form-label-sm fw-bold">{{ 'FORM.FECHA_INICIO' | translate
                            }}:</label>
                        <div class="col-4">
                            <input matInput [matDatepicker]="picker" class="text-alcoa text-center form-control form-control-sm form-control-calendar" [max]="hoy"
                                [ngClass]="fechaIni.hasError('required') && bajasFrm.submitted ? 'obligatorio' : ''" [(ngModel)]="baja.fechaInicio" name="fechaIni" #fechaIni="ngModel" readonly
                                [required]="baja.idTipoIncapacidadTemporal != 2">
                            <button type="button" class="btn btn-sm text-alcoa" (click)="clearDate(fechaIni)" [disabled]="esUsuario && baja.idIncapacidadTemporal && baja.idEstado != 1">
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                            <mat-datepicker-toggle matSuffix [for]="picker" class="text-alcoa" [disabled]="esUsuario && baja.idIncapacidadTemporal && baja.idEstado != 1"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <label id="lblFechaFin" for="fechaFin" class="col-lg-2 col-md-2 col-sm-4 align-self-center col-form-label col-form-label-sm fw-bold">{{ 'FORM.FECHA_FIN' | translate }}:</label>
                        <div class="col-4">
                            <input matInput [matDatepicker]="pickerFin" class="text-alcoa text-center form-control form-control-sm form-control-calendar" [min]="baja.fechaInicio"
                                [ngClass]="fechaFin.hasError('matDatepickerMin') && bajasFrm.submitted ? 'obligatorio' : ''" [(ngModel)]="baja.fechaFin" name="fechaFin" #fechaFin="ngModel" readonly>
                            <button type="button" class="btn btn-sm text-alcoa" (click)="clearDate(fechaFin)">
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="text-alcoa"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFin></mat-datepicker>
                        </div>
                    </div>
                </div>
                <label id="lblDias" class="col-1 pt-2 col-form-label col-form-label-sm fw-bold" *ngIf="!esUsuario">Días Est:</label>
                <div class="col-1" *ngIf="!esUsuario">
                    <input type="number" class="form-control form-control-sm" [(ngModel)]="baja.dias" name="dias" #dias="ngModel">
                </div>
            </div>
            
            <div class="form-group row pt-1" *ngIf="!esUsuario">
                <div class="col-lg-2 col-md-3 col-sm-4">
                    <label class="col-form-label col-form-label-sm fw-bold" for="observaciones">{{ 'FORM.OBSERVACIONES' | translate }}:</label>
                </div>
                <div class="col-lg-10 col-md-9 col-sm-8">
                    <textarea class="form-control form-control-sm" id="observaciones" name="observaciones" [(ngModel)]="baja.observaciones" rows="4" [disabled]="esUsuario" (blur)="actualizarDescripcion()"></textarea>
                </div>
            </div>
            <div class="form-group row pt-1" *ngIf="baja.idTipoIncapacidadTemporal != 3">
                <div class="custom-file col-6">
                    <input class="form-control form-control-sm" type="file" #file (change)="subirFichero($event.target.files)" required
                        [ngClass]="bajasFrm.submitted && !fileToUpload ? 'obligatorio' : '' " [disabled]="esRevision" language="translate.currentLang">
                </div>
                <label class="col-2 ps-2 pt-1">Max. 5 Mb</label>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="button" class="btn btn-alcoa float-end ms-2"
                        [disabled]="(bajaPendiente && !baja.idIncapacidadTemporal) || (baja.idIncapacidadTemporal && baja.fechaInicio && !baja.fechaFin)" (click)="altaIncapacidadTemporal(bajasFrm)">
                        <fa-icon [icon]="faSave"></fa-icon> Guardar
                    </button>
                    <button type="button" class="btn btn-alcoa float-end" [disabled]="!bajaPendiente || baja.fechaFin" (click)="altaParteConfirmacion(bajasFrm)">
                        <fa-icon [icon]="faPlus"></fa-icon> Parte Confirmacion
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="row mt-3 pt-2 border-top border-alcoa" style="width:100%; overflow: auto" [hidden]="!empleado.idUsuario">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="expandir">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5"> </th>
                <td mat-cell *matCellDef="let element" class="w5 p-0">
                    <a mat-button (click)="element.isExpanded = !element.isExpanded" [hidden]="element.idTipoIncapacidadTemporal == 3">
                        <fa-icon *ngIf="!element.isExpanded" [icon]="faPlus"></fa-icon>
                        <fa-icon *ngIf="element.isExpanded" [icon]="faMinus"></fa-icon>
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w20 ps-2"> {{ 'FORM.TIPO_IT' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w20 ps-2"> {{element.tipo}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaIni">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.FECHA_INICIO' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.fechaInicio | date :
                    'mediumDate':'':translate.currentLang}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaFin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.FECHA_FIN' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.fechaFin | date :
                    'mediumDate':'':translate.currentLang}} </td>
            </ng-container>
            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-2"> {{ 'FORM.OBSERVACIONES' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="ps-2"> {{element.observaciones}} </td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.ESTADO' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2"> {{element.estado}} </td>
            </ng-container>
            <ng-container matColumnDef="adjunto">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button mat-icon-button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" [matMenuTriggerFor]="adjunto" *ngIf="element.incapacidadTemporalAdjuntos.length > 0">
                        <fa-icon [icon]="faPaperClip"></fa-icon>
                    </button>
                    <mat-menu #adjunto="matMenu">
                        <button mat-menu-item *ngFor="let file of element.incapacidadTemporalAdjuntos" (click)="verFichero(file.idIncapacidadTemporalAdjunto)" class="btn btn-sm btn-alcoa-transparente"
                            style="font-size:10px;">
                            <fa-icon [icon]="faFile"></fa-icon> <span>{{file.nombre}}</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button *ngIf="(esUsuario && element.idEstado == 1 && element.fechaFin == null) || ([1,2].includes(element.idEstado) && !esUsuario)" class="btn btn-sm btn-danger "
                        style="font-size:10px;" (click)="confirmarBorrar(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>
            <!-- Template for details row -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayColumns.length">
                    <div class="row detalle-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                        <table class="table text-alcoa" style="width:99%;">
                            <tr *ngFor="let parte of element.incapacidadTemporalAdjuntos">
                                <td class="w10">{{parte.fechaAlta | date : 'mediumDate':'':translate.currentLang}}</td>
                                <td>{{getDescripcion(parte)}}</td>
                                <td>{{parte.nombre}}</td>
                                <td style="text-align:right;"><button class="btn btn-sm btn-alcoa-transparente" (click)="verFichero(parte.idIncapacidadTemporalAdjunto)">
                                        <fa-icon [icon]="faEye"></fa-icon>
                                    </button></td>
                                <td class="text-end">
                                    <button *ngIf="(esUsuario && element.idEstado == 1 && element.fechaFin == null) || ([1,2].includes(element.idEstado) && !esUsuario)"
                                        class="btn btn-sm btn-outline-danger p-1" style="font-size:10px;" (click)="confirmarBorrar(parte, element)">
                                        <fa-icon [icon]="faTimes"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{ 'selected': row === selectedRow}" [class.detalle-expanded-row]="row.isExpanded"> </tr>
            <!-- Extra row to show detail content column -->
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detalle-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>