
import { LabelValue } from "./labelvalue";
import { Empleado } from "./empleado";

export class IncapacidadTemporal {
    public idIncapacidadTemporal: number;
    public idUsuario: number;
    public usuario: Empleado;
    public fechaInicio: Date;
    public fechaFin: Date;
    public idUsuarioAlta: number;
    public fechaAlta: Date;
    public idUsuarioModificacion: number;
    public fechaModificacion: Date;
    public idEstado: number;
    public incapacidadTemporalAdjuntos : IncapacidadTemporalAdjunto[];
    public idTipoIncapacidadTemporal: number;
    public tipo: string;
    public dias: number;
    public diasBaja: number;
    public observaciones: string;

    public isExpanded: boolean = false;
    public tienePartes: boolean = false;

    constructor() { }
}

export class ParteConfirmacion {
    public idIncapacidadTemporalParteConfirmacion: number;
    public idIncapacidadTemporal: number;
    public idUsuarioAlta: number;
    public fechaAlta: Date;
    public idAdjunto: number;
    public nombreAdjunto: string;

    constructor() {}
}

export class IncapacidadTemporalAdjunto {
    public idIncapacidadTemporalAdjunto: number;
    public idIncapacidadTemporalParteConfirmacion: number;
    public nombre: string;
    public fechaAlta: Date;
    public idEstado: number;

    constructor() {}
}

export class TipoIncapacidadTemporal {
    public idTipoIncapacidadTemporal: number;
    public descripcion: string;

    constructor() {}
}