<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">{{ 'AYUDAS.BENEFICIARIOS_TITULO' | translate }}</h1>
    </div>
    <div class="row pt-2">
        <app-buscadorempleado (newItemEvent)="buscarEmpleado($event)" [verLimpiar]=true></app-buscadorempleado>
    </div>
    <form id="beneficiariosFrm" name="beneficiariosFrm" class="needs-validation pt-3" novalidate
        *ngIf="empleado.idUsuario">
        <div class="row border-bottom border-alcoa">
            <h2 class="col-12">{{ 'FORM.BENEFICIARIO' | translate }}</h2>
        </div>
        <div class="row pt-2">
            <div class="form-group col-2">
                <label class="col-form-label col-form-label-sm fw-bold" for="nombre">{{ 'FORM.NOMBRE' | translate }}</label>
                <input type="text" class="form-control form-control-sm" id="nombre" name="nombre"
                    [(ngModel)]="beneficiario.nombre" [ngClass]="!valido && !beneficiario.nombre ? 'obligatorio' : '' ">
            </div>
            <div class="form-group col-3">
                <label class="col-form-label col-form-label-sm fw-bold" for="apellidos">{{ 'FORM.APELLIDOS' | translate }}</label>
                <input type="text" class="form-control form-control-sm" id="apellidos" name="apellidos"
                    [(ngModel)]="beneficiario.apellidos" [ngClass]="!valido && !beneficiario.apellidos ? 'obligatorio' : '' ">
            </div>
            <div class="form-group col-2">
                <label class="col-form-label col-form-label-sm fw-bold" for="idParentesco">{{ 'FORM.PARENTESCO' | translate }}</label>
                <select class="form-control form-control-sm" id="idParentesco" name="idParentesco"
                    [(ngModel)]="beneficiario.parentesco.value" [ngClass]="!valido && !beneficiario.parentesco.value ? 'obligatorio' : '' ">
                    <option></option>
                    <option [ngValue]="parentesco.value" *ngFor="let parentesco of parentescos">{{parentesco.label}}
                    </option>
                </select>
            </div>
            <div class="form-group col-2">
                <label class="col-form-label col-form-label-sm fw-bold" for="fecha">{{
                    'FORM.FECHA_NACIMIENTO' | translate }}</label>
                <input matInput [matDatepicker]="pickerFn"
                    class="text-alcoa text-center form-control form-control-sm form-control-calendar"
                    [ngClass]="!valido && !fechaNac.value ? 'obligatorio' : '' "
                    [formControl]="fechaNac" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerFn" class="text-alcoa"></mat-datepicker-toggle>
                <mat-datepicker #pickerFn></mat-datepicker>
            </div>
            <div class="form-group col-2">
                <label class="col-form-label col-form-label-sm fw-bold" for="fecha">{{ 'FORM.FECHA_BENEFICIO' |  translate }}</label>
                <input matInput [matDatepicker]="picker"
                    class="text-alcoa text-center form-control form-control-sm form-control-calendar"
                    [formControl]="date" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker" class="text-alcoa"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="form-group col-1 mt-auto text-alcoa-blanco">
                <button id="limpiaBeneficiario" type="button" class="btn btn-alcoa-PMS320 btn-sm float-end ms-1"
                    (click)="limpiarFormulario()">
                    <fa-icon [icon]="faEraser"></fa-icon>
                </button>
                <button id="addBeneficiario" type="button" class="btn btn-success btn-sm float-end"
                    (click)="guardarBeneficiario()">
                    <fa-icon [icon]="beneficiario.idBeneficiario ? faSave : faPlus"></fa-icon>
                </button>
            </div>
        </div>
    </form>
    <div class="pt-3" style="width:100%; overflow: auto" *ngIf="empleado.idUsuario">
        <table mat-table [dataSource]="dataSource" matSort
            class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle">
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> {{ 'FORM.NOMBRE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="ps-3 w20"> {{element.nombre }} </td>
            </ng-container>
            <ng-container matColumnDef="apellidos">
                <th mat-header-cell *matHeaderCellDef>  {{'FORM.APELLIDOS' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="ps-3"> {{ element.apellidos }} </td>
            </ng-container>
            <ng-container matColumnDef="parentesco">
                <th mat-header-cell *matHeaderCellDef>  {{'FORM.PARENTESCO' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="ps-3 w15"> {{ element.parentesco.label }} </td>
            </ng-container>
            <ng-container matColumnDef="fechaNacimiento">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.FECHA_NACIMIENTO' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="text-center w10"> {{ element.fechaNacimiento | date:'shortDate':'':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="edad">
                <th mat-header-cell *matHeaderCellDef> Edad </th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="text-end pe-2"> {{element.edad |  number : '1.0-0'}} </td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> Benef. S.S. </th>
                <td mat-cell *matCellDef="let element" (click)="seleccionarBeneficiario(element)" class="text-center w10"> {{ element.fechaBeneficiario | date:'shortDate':'':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="w5">
                    <button class="btn btn-danger btn-sm float-end" style="font-size:10px;" (click)="confirmarBorrarBeneficiario(element);">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
    </div>
</div>