export class Autenticacion {
    error: number;
    expires_in: number;
    id: number;
    tokensPlanta: TokenPlanta[];
}

export enum AutenticacionErrorCode {
    NoError = 0,
    InvalidCredentials = -1,
    NotAuthorized = -2
}

export class TokenPlanta {
    planta: string;
    rol: string;
    token: string;
}