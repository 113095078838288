<div class="container-fluid bg-alcoa h-100">
    <div class="row">
        <!-- Icono Alcoa -->
        <div class="ico-carpeta" style="text-align: center;">
            <img [src]="'assets/img/AlcoaLogo.png'" alt="logo" title="logo" class="img-responsive">
            <!-- <span class="separador-vertical"></span> -->
            <h1 class="text-white"><span>Atenciones Sociales</span></h1>
        </div>
    </div>
    <div class="row">
        <!-- Formulario -->
        <form [formGroup]="loginForm" class="form-login" (ngSubmit)="login(loginForm.value)">
            <fieldset>
                <div class="form-group offset-4 col-4" *ngIf="esDev()">
                    <label for="usuar"></label>
                    <input type="text" formControlName="usuar" class="first-name form-login" id="usuar"
                        placeholder="Login">
                </div>
                <div class="form-group offset-4 col-4" *ngIf="esDev()">
                    <label for="passw"></label>
                    <input type="password" formControlName="lpassw" class="last-name form-login" id="passw"
                        placeholder="Password">
                </div>
                <div class="form-group offset-4 col-4" *ngIf="esDev()">
                    <button class="btn btn-alcoa-PMS320 text-white" type="submit">{{ 'BOTONES.ACEPTAR' | translate }}</button>
                </div>
                <div class="form-group offset-4 col-4">
                    <button class="btn btn-alcoa-PMS320 text-white" type="button" (click)="ssoLogin()">{{
                        'BOTONES.SSO_LOGIN' | translate }}</button>
                </div>
            </fieldset>
        </form>
    </div>
</div>