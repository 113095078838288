import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Beneficiario } from '../model/beneficiario';
import { LabelValue } from '../model/labelvalue';

@Injectable({
  providedIn: 'root'
})
export class BeneficiarioService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getBeneficiarios(idUsuario: number) : Observable<Beneficiario[]> {
    return this.httpClient.get<Beneficiario[]>(`${this.URL}api/beneficiarios/${idUsuario}`);
  }

  altaBeneficiario(beneficiario: Beneficiario) {
    return this.httpClient.post(`${this.URL}api/beneficiarios`, beneficiario);
  }

  modificaBeneficiario(beneficiario: Beneficiario) {
    return this.httpClient.put(`${this.URL}api/beneficiarios`, beneficiario);
  }

  bajaBeneficiario(idBeneficiario: number) {
    return this.httpClient.delete(`${this.URL}api/beneficiarios/${idBeneficiario}`);
  }

  getParentescos() : Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(`${this.URL}api/parentescos`);
  }
}
