import { ElementRef } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faCheck, faCheckDouble, faEraser, faExclamationTriangle, faPaperclip, faQuestion, faSave, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Aviso } from 'src/app/model/aviso';
import { Beneficiario } from 'src/app/model/beneficiario';
import { Empleado } from 'src/app/model/empleado';
import { Presupuesto } from 'src/app/model/presupuesto';
import { TipoAyudaContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { DenegardialogComponent } from '../aprobacionhr/denegardialog/denegardialog.component';

@Component({
  selector: 'app-presupuesto',
  templateUrl: './presupuesto.component.html',
  styleUrls: ['./presupuesto.component.scss']
})
export class PresupuestoComponent implements OnInit {
  faSave = faSave;
  faEraser = faEraser;
  faTimes = faTimes;
  faPaperClip = faPaperclip;

  empleado: Empleado = new Empleado();
  presupuesto: Presupuesto = new Presupuesto();
  tipoAyudas: TipoAyudaContrato[] = [];
  beneficiarios: Beneficiario[] = [];
  valido: boolean = true;
  fileToUpload: File | null = null;
  tipoAyudaSel: TipoAyudaContrato;

  tipoAyudaCampamento: number[] = [2];
  tipoAyudaContratoCampamento: number[] = [];
  esSolicitud: boolean = false;

  titulo: string = "Solicitud de presupuestos";

  @ViewChild('file') inputFile: ElementRef;

  @Input() idUsuario: number;
  esRevision: boolean = false;
  esUsuario: boolean = false;

  // Tabla
  dataSource: MatTableDataSource<Presupuesto>;
  displayColumns: string[] = ['id', 'ayuda', 'beneficiario', 'importe', 'acumulado', 'estado', 'observaciones', 'adjunto', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Presupuesto;

  constructor(private ayudasService: AyudasService, private presupuestosService: PresupuestosService, private beneficiarioService: BeneficiarioService, private translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");
    if (ps.includes("Inespal")) {
      this.titulo = "Solicitud de presupuestos";
    }
    let plantaUsuario: TokenPlanta = JSON.parse(ps);
    let rolesUsuario = plantaUsuario.rol.split(",");
    // Si es el rol "usuario" tiene que rellenar con su expediente y nombre
    if (this.idUsuario) {
      this.esRevision = true;
    } else if (rolesUsuario.includes("usuario")) {
      let id = localStorage.getItem("idUsuarioAtenciones") ? localStorage.getItem("idUsuarioAtenciones") : sessionStorage.getItem("idUsuarioAtenciones");
      this.idUsuario = Number.parseInt(id);
      this.esUsuario = true;
    }
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  buscarEmpleado(empleado: Empleado): void {
    this.limpiarPresupuesto();
    this.empleado = empleado;
    this.buscarTipoAyudas();
    this.buscarBeneficiario();
  }

  buscarBeneficiario(): void {
    this.beneficiarioService.getBeneficiarios(this.empleado.idUsuario).subscribe({
      next: result => {
        this.beneficiarios = result;
      },
      error: error => console.error(error)
    });
  }

  buscarTipoAyudas(): void {
    this.ayudasService.getTipoAyudas(this.empleado.idContractType).subscribe({
      next: result => {
        let aux: TipoAyudaContrato[] = result.filter(ta => this.tipoAyudaCampamento.includes(ta.idTipoAyuda));
        aux.forEach(tac => this.tipoAyudaContratoCampamento.push(tac.idTipoAyudaContrato));
        this.tipoAyudas = result.filter(ta => !this.tipoAyudaCampamento.includes(ta.idTipoAyuda));
        this.buscarPresupuestos();
      },
      error: error => console.error(error)
    });
  }

  buscarPresupuestos(): void {
    this.presupuestosService.getPresupuestosUsuario(this.empleado.idUsuario).subscribe(result => {
      if (this.esUsuario) {
        this.dataSource.data = result.filter(p => p.idEstado != 4 && !this.tipoAyudaContratoCampamento.includes(p.idTipoAyuda));
      } else {
        this.dataSource.data = result.filter(p => !this.tipoAyudaContratoCampamento.includes(p.idTipoAyuda));
      }
      this.dataSource = this.dataSource;
    });
  }

  limpiarPresupuesto(): void {
    this.presupuesto = new Presupuesto();
    delete this.tipoAyudaSel;
    this.selectedRow = null;
    this.fileToUpload = null;
    if (this.inputFile && this.inputFile.nativeElement) {
      this.inputFile.nativeElement.value = '';
    }
  }

  seleccionarPresupuesto(presupuesto: Presupuesto): void {
    if (this.presupuesto.idPresupuesto == presupuesto.idPresupuesto) {
      this.limpiarPresupuesto();
    } else {
      const myJSON = JSON.stringify(presupuesto);

      this.presupuesto = JSON.parse(myJSON);
      this.selectedRow = presupuesto;
      this.tipoAyudaSel = new TipoAyudaContrato();
      this.tipoAyudaSel.idTipoAyudaContrato = this.presupuesto.idTipoAyuda;
      this.tipoAyudaSel.descripcion = this.presupuesto.descTipoAyuda;
    }
  }

  validar(): boolean {
    this.valido = true;
    if (!this.presupuesto.idTipoAyuda) {
      this.valido = false;
    }
    if (this.esSolicitud && !this.presupuesto.idBeneficiario) {
      this.valido = false;
    }
    if ((!this.presupuesto.importe || this.presupuesto.importe == 0) && !this.esSolicitud) {
      this.valido = false;
    }
    if (!this.esSolicitud && this.esUsuario && !this.fileToUpload && !this.presupuesto.idPresupuestoAdjunto) {
      this.valido = false;
    }

    return this.valido;
  }

  altaPresupuesto(): void {
    if (this.validar()) {
      this.presupuesto.idUsuario = this.empleado.idUsuario;
      if (this.presupuesto.idPresupuesto) {
        this.presupuestosService.modificacionPresupuesto(this.presupuesto).subscribe(result => {
          if (result && this.fileToUpload) {
            if (this.presupuesto.idPresupuestoAdjunto) {
              this.presupuestosService.deletePresupuestoAdjunto(this.presupuesto.idPresupuestoAdjunto).subscribe();
            }
            this.presupuestosService.altaPresupuestoAdjunto(this.presupuesto, this.fileToUpload).subscribe(result => {
              this.limpiarPresupuesto();
              this.buscarPresupuestos();
            });
          } else {
            this.limpiarPresupuesto();
            this.buscarPresupuestos();
          }
        });
      } else {
        if (this.esSolicitud) {
          this.presupuesto.idEstado = 1;
        }
        this.presupuestosService.altaPresupuesto(this.presupuesto).subscribe(result => {
          if (result.idPresupuesto) {
            if (this.fileToUpload) {
              this.presupuestosService.altaPresupuestoAdjunto(result, this.fileToUpload).subscribe(result => {
                this.limpiarPresupuesto();
                this.buscarPresupuestos();
              });
            } else {
              this.limpiarPresupuesto();
              this.buscarPresupuestos();
            }
          }
        });
      }
    }
  }

  subirFichero(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  borrarFichero() {
    this.presupuestosService.deletePresupuestoAdjunto(this.presupuesto.idPresupuestoAdjunto).subscribe(result => {
      this.presupuesto.nombreFichero = null;
      this.presupuesto.idPresupuestoAdjunto = null;
    });
  }

  verFichero(presupuesto: Presupuesto): void {
    this.presupuestosService.getPresupuestoAdjunto(presupuesto.idPresupuestoAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  confirmarBorrarPresupuesto(presupuesto: Presupuesto): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a eliminar el presupuesto ${presupuesto.descTipoAyuda}. ¿Desea continuar?`;

    if (presupuesto.idEstado == 1) {
      const dialogRef = this.dialog.open(DenegardialogComponent, {
        width: "400px",
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Si el presupuesto estaba borrado se hace un borrado logico (idEstado = 4)
          presupuesto.idEstado = 4;
          presupuesto.observaciones = result;
          this.presupuestosService.modificacionPresupuesto(presupuesto).subscribe(result => {
            this.limpiarPresupuesto();
            this.buscarPresupuestos();
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
        width: "400px",
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.borraPresupuesto(presupuesto);
        }
      });
    }
  }

  borraPresupuesto(presupuesto: Presupuesto): void {
    this.presupuestosService.eliminaPresupuesto(presupuesto.idPresupuesto).subscribe({
      next: result => {
        if (result) {
          this.limpiarPresupuesto();
          this.buscarPresupuestos();
        }
      },
      error: error => console.error(error)
    });
  }

  onFocusOutImporte(event: any) {
    // Controlar valor negativo
    if (this.presupuesto.importe < 0) {
      this.presupuesto.importe = Math.abs(this.presupuesto.importe);
    }
  }

  public getIconoEstado(presupuesto: Presupuesto): IconDefinition {
    let icono: IconDefinition;
    switch (presupuesto.idEstado) {
      case 0:
        icono = faQuestion;
        break;
      case 1:
        icono = faCheck;
        break;
      case 2:
        icono = faTimes;
        break;
      case 3:
        icono = faCheckDouble;
        break;
      default:
        break;
    }

    return icono;
  }

  public getClaseEstado(presupuesto: Presupuesto): string {
    if (presupuesto.idEstado == 0) {
      return "text-warning";
    } else if (presupuesto.idEstado == 1) {
      return "text-success";
    } else if (presupuesto.idEstado == 2) {
      return "text-danger";
    } else {
      return "";
    }
  }

  seleccionTipo(): void {
    if (this.tipoAyudaSel) {
      this.presupuesto.idTipoAyuda = this.tipoAyudaSel.idTipoAyudaContrato;
      this.esSolicitud = this.tipoAyudaCampamento.includes(this.tipoAyudaSel.idTipoAyuda);
      if (this.esSolicitud) {
        this.presupuesto.importe = 664.04;
        let anoIni = new Date().getFullYear() - 17;
        let anoFin = new Date().getFullYear() - 6;
        let fechaIni: Date = new Date(anoIni, 5, 1, 0, 0, 0);
        let fechaFin = new Date(anoFin, 5, 1, 0, 0, 0);
        this.beneficiarios = this.beneficiarios.filter(b => b.parentesco.value == 2 && new Date(b.fechaNacimiento).getTime() >= fechaIni.getTime() && new Date(b.fechaNacimiento).getTime() < fechaFin.getTime());
      } else {
        if (!this.presupuesto.idPresupuesto) {
          delete this.presupuesto.importe;
        }
        this.buscarBeneficiario();
      }
    }
  }

  compareTipo(tac1: TipoAyudaContrato, tac2: TipoAyudaContrato): boolean {
    return tac1 && tac2 ? tac1.idTipoAyudaContrato === tac2.idTipoAyudaContrato : tac1 === tac2;
  }

}
