<h2 mat-dialog-title class="text-alcoa border-bottom border-alcoa-gris"> Ayudas Estudios</h2>
<mat-dialog-content class="mat-typography text-alcoa">
    <form id="cursoFrm" name="cursoFrm" class="text-alcoa needs-validation" (ngSubmit)="guardar(cursoFrm)" #cursoFrm="ngForm" novalidate>
        <div class="row">
            <div class="form-group col-4">
                <label class="col-form-label col-form-label-sm fw-bold">Curso</label>
                <select class="form-control form-control-sm" [(ngModel)]="cursoSel" name="curso" id="curso" [compareWith]="compareCurso" [disabled]="data.idCurso" required>
                    <option *ngFor="let curso of cursos" [ngValue]="curso">{{curso.label}}</option>
                </select>
            </div>
            <div class="form-group col-3">
                <label class="col-form-label col-form-label-sm fw-bold">Tipo Contrato</label>
                <select class="form-control form-control-sm" [(ngModel)]="tipoContratoSel" name="tipoContrato" id="tipoContrato" [compareWith]="compareTipos" required>
                    <option *ngFor="let tipo of tiposContrato" [ngValue]="tipo">{{tipo.label}}</option>
                </select>
            </div>
            <div class="form-group col-3">
                <label class="col-form-label col-form-label-sm fw-bold">Importe</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="data.importe" name="importe" id="importe" required>
            </div>
            <div class="form-group col-2">
                <label class="col-form-label col-form-label-sm fw-bold">Doc. Opcional</label>
                <mat-slide-toggle color="primary" [(ngModel)]="data.docOpcional" name="docopcional" id="docopcional"></mat-slide-toggle>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="border-top border-alcoa-gris mt-3">
    <button type="submit" class="btn btn-alcoa me-2" form="cursoFrm" *ngIf="!data.idAyudaEstudiosCurso">
        <fa-icon [icon]="faSave"></fa-icon> Guardar
    </button>
    <button class="btn btn-alcoa me-2" (click)="modificar()" *ngIf="data.idAyudaEstudiosCurso">
        <fa-icon [icon]="faPenSquare"></fa-icon> Modificar
    </button>
    <button class="btn btn-danger me-2" (click)="eliminar()"  *ngIf="data.idAyudaEstudiosCurso">
        <fa-icon [icon]="faTimes"></fa-icon> Eliminar
    </button>
    <button mat-dialog-close class="btn btn-secondary" cdkFocusInitial>
        <fa-icon [icon]="faDoorClosed"></fa-icon> Cancelar
    </button>
</mat-dialog-actions>
