import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Nomina } from 'src/app/model/nomina';
import { AyudasService } from 'src/app/services/ayudas.service';
import * as XLSX from 'xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-informenominas',
  templateUrl: './informenominas.component.html',
  styleUrls: ['./informenominas.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class InformenominasComponent implements OnInit {
  faSearch = faSearch;
  faExcel = faFileExcel;

  date = new UntypedFormControl(moment());

  columnas = ['empresa', 'codigo', 'empleado'];
  columnsInespal = ['N502', 'N530', 'N531', 'N547', 'N548', 'N549', 'N669', 'N678'];
  columnsSanCiprian = ['N529', 'N530', 'N533', 'N537', 'N538', 'N544', 'N547'];
  columnasVer = [];

  // Tabla
  dataSource: MatTableDataSource<Nomina>;
  displayColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService) { }

  ngOnInit(): void {
    this.buscar();
  }

  buscar(): void {
    this.columnasVer = [];
    this.displayColumns = [];
    this.displayColumns.push(...this.columnas);
    const ctrlValue = this.date.value;
    this.ayudasService.getAyudasNomina(ctrlValue.month() + 1, ctrlValue.year()).subscribe({
      next: result => {
        if (result && result.length > 0) {
          if (result[0].empresa == '101') {
            this.displayColumns.push(...this.columnsInespal);
            this.columnasVer = this.columnsInespal;
          } else {
            this.displayColumns.push(...this.columnsSanCiprian);
            this.columnasVer = this.columnsSanCiprian;
          }
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      error: error => console.error(error)
    });
  }

  getImporte(nomina: Nomina, codigo: string): number {
    let importe: number = 0;
    if (nomina.importes.some(i => i.label == codigo)) {
      importe = nomina.importes.filter(i => i.label == codigo)[0].value;
    }

    return importe;
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    this.date.setValue(ctrlValue);

    datepicker.close();
    this.buscar();
  }

  exportar(): void {
    //Had to create a new workbook and then add the header
    var Heading = [['Empresa', 'Codigo']];
    Heading[0].push(...this.columnasVer);
    if (this.dataSource != undefined) {
      var prepare = [];
      this.dataSource.data.forEach(a => {
        var tempObj = [];
        tempObj.push(a.empresa);
        tempObj.push(a.codigo);
        this.columnasVer.forEach(i => {
          tempObj.push(this.getImporte(a, i));
        });
        prepare.push(tempObj);
      });
      const workSheet = XLSX.utils.json_to_sheet([]);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(workSheet, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, prepare, { origin: 'A2', skipHeader: true });

      XLSX.utils.book_append_sheet(workBook, workSheet, 'InformeNominas');
      XLSX.writeFile(workBook, 'InformeNominas.xlsx');
    }
  }

}
