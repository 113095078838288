import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Autenticacion } from '../model/autenticacion';
import { LoginRequestSSO } from '../model/loginRequestSSO';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  EMMA_URL: string = environment.emmaUrl;

  constructor(private httpClient: HttpClient) { }

  login(usuario, password, application): Observable<Autenticacion> {
    var jsonData = {"login": usuario, "contrasena": password, "appGuid": application};
    return this.httpClient.post<Autenticacion>(`${this.EMMA_URL}api/token`, jsonData);
  }

  loginSSO(request: LoginRequestSSO): Observable<Autenticacion> {
		return this.httpClient.post<Autenticacion>(`${this.EMMA_URL}api/tokenSSO`, request);
	}
}
