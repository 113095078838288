import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LabelValue } from '../model/labelvalue';
import { Presupuesto } from '../model/presupuesto';

@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getPresupuestosUsuario(idUsuario: number) : Observable<Presupuesto[]> {
    return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Presupuestos/${idUsuario}`);
  }

  getPresupuestosPendientes() : Observable<Presupuesto[]> {
    return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Presupuestos/pendientes`);
  }

  getEstadosPresupuestos() : Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(`${this.URL}api/Presupuestos/estados`);
  }

  getSolicitudesCampamentos(ano: number) : Observable<Presupuesto[]> {
    return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Presupuestos/solicitudes/${ano}`);
  }

  getPresupuestosUsuarioAyuda(idUsuario: number, idTipoAyuda: number, idBeneficiario: number) : Observable<Presupuesto[]> {
    if (idBeneficiario) {
      return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Presupuestos/${idUsuario}/${idTipoAyuda}?idBeneficiario=${idBeneficiario}`);
    } else {
      return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Presupuestos/${idUsuario}/${idTipoAyuda}`);
    }
  }

  altaPresupuesto(presupuesto: Presupuesto) : Observable<Presupuesto> {
    return this.httpClient.post<Presupuesto>(`${this.URL}api/Presupuestos`, presupuesto);
  }

  modificacionPresupuesto(presupuesto: Presupuesto) : Observable<Presupuesto> {
    return this.httpClient.put<Presupuesto>(`${this.URL}api/Presupuestos`, presupuesto);
  }

  eliminaPresupuesto(idPresupuesto: number) : Observable<number> {
    return this.httpClient.delete<number>(`${this.URL}api/Presupuestos/${idPresupuesto}`);
  }

  getPresupuestoAdjunto(idPresupuestoAdjunto: number) : Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.URL}api/PresupuestoAdjuntos/${idPresupuestoAdjunto}`, { observe: 'body', responseType: 'blob' as 'json' });
  }

  altaPresupuestoAdjunto(presupuesto: Presupuesto, fileToUpload: File): Observable<number> {
    console.log(presupuesto);
    const formData: FormData = new FormData();
    formData.append('adjunto', fileToUpload, fileToUpload.name);
    formData.append('idPresupuesto', JSON.stringify(presupuesto.idPresupuesto));
    return this.httpClient.post<number>(`${this.URL}api/PresupuestoAdjuntos`, formData);
  }

  deletePresupuestoAdjunto(idAyudaAdjunto: number): Observable<number> {
    return this.httpClient.delete<number>(`${this.URL}api/PresupuestoAdjuntos/${idAyudaAdjunto}`);
  }

  enviarMailPrespuesto(presupuesto: Presupuesto) {
    return this.httpClient.post(`${this.URL}api/Email/presupuesto`, presupuesto);
  }
}
