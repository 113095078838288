<div class="container-fluid text-alcoa">
    <div class="row col-12 border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Informe Nómina</h1>
    </div>
    <div class="row pt-2">
        <label id="lblFecha" class="col-lg-1 col-md-2 col-sm-4 col-form-label fw-bold">Fecha:</label>
        <div class="col-lg-2 col-md-4 col-sm-7 align-self-center">
            <input matInput [matDatepicker]="picker" class="text-alcoa text-center form-control form-control-sm form-control-calendar" [formControl]="date" readonly (click)="picker.open()">
            <mat-datepicker #picker startView="year" (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
        </div>
        <div class="offset-lg-8 offset-md-5 col-1 text-end">
            <button class="btn btn-excel" (click)="exportar()"><fa-icon [icon]="faExcel"></fa-icon></button>
        </div>
    </div>
    <div class="row">
    </div>
    <div class="col-12 pt-2" style="width:100%; overflow: auto; font-size: 12px;">
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="empresa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                <td mat-cell *matCellDef="let element" class="w10"> {{element.empresa}} </td>
            </ng-container>
            <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef> Código </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10"> {{ element.codigo }} </td>
            </ng-container>
            <ng-container matColumnDef="empleado">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10"> {{ element.empleado }} </td>
            </ng-container>
            <ng-container [matColumnDef]="i" *ngFor="let i of columnasVer">
                <th mat-header-cell *matHeaderCellDef> {{i}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w5"> {{ getImporte(element, i) }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
