<div class="container-fluid text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Personal de Baja</h1>
    </div>
    <form>
        <div class="row mb-2 mt-1">
            <label class="col-2 col-form-label col-form-label-sm fw-bold pt-2">{{ 'FORM.FECHA_INICIO' | translate }}:</label>
            <div class="col-3">
                <mat-date-range-input [formGroup]="rangoFecha" [rangePicker]="pickerAlta" class="form-control form-control-sm form-control-calendar">
                    <input matStartDate formControlName="start" placeholder="Fecha Inicio" readonly>
                    <input matEndDate formControlName="end" placeholder="Fecha Fin" readonly>
                </mat-date-range-input>
                <button class="btn btn-sm text-alcoa" (click)="clearDate(rangoFecha)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="pickerAlta"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerAlta></mat-date-range-picker>
            </div>
            <mat-slide-toggle class="col-2 text-alcoa fw-bold mt-2" color="primary" [(ngModel)]="actuales" [ngModelOptions]="{standalone: true}">Bajas Actuales</mat-slide-toggle>
            <div class="col-5">
                <button type="button" class="float-end btn btn-alcoa" (click)="buscar()"><fa-icon [icon]="faSearch"></fa-icon> Buscar</button>
            </div>
        </div>
    </form>
    <div class="pt-2" style="width:100%; overflow: auto">
        <button type="button" class="btn btn-excel me-3" (click)="exportar()"><fa-icon [icon]="faFileExcel"></fa-icon></button>
        <mat-form-field appearance="standard">
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Digestion" #input>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="tipoContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5"> NIV </th>
                <td mat-cell *matCellDef="let element" class="w5"> {{element.usuario.tipoContrato}} </td>
            </ng-container>
            <ng-container matColumnDef="jornada">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5"> JORN </th>
                <td mat-cell *matCellDef="let element" class="w5"> {{element.usuario.jornada}} </td>
            </ng-container>
            <ng-container matColumnDef="expediente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> {{ 'FORM.EXPEDIENTE' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w5 ps-2"> {{element.usuario.expediente}} </td>
            </ng-container>
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w20 ps-2"> {{ 'FORM.NOMBRE' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w20 ps-2"> {{element.usuario.nombre}} </td>
            </ng-container>
            <ng-container matColumnDef="departamento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5"> {{ 'BUSCA_PERSONAL.DEPARTAMENTO' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w5"> {{element.usuario.dpto}} </td>
            </ng-container>
            <ng-container matColumnDef="seccion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> SECCION </th>
                <td mat-cell *matCellDef="let element" class="w5 ps-2"> {{ (element.usuario.seccion | number : '5.0-0').replaceAll(',', '').replaceAll('.', '')}} </td>
            </ng-container>
            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10 ps-2"> {{ 'FORM.TIPO_IT' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10 ps-2" (click)="[1,2].includes(element.idEstado) ? seleccionar(element) : return;"> {{element.tipo}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaIni">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> {{ 'FORM.FECHA_INICIO' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w5 ps-2"> {{element.fechaInicio | date : 'mediumDate':'':translate.currentLang}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaFin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> {{ 'FORM.FECHA_FIN' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w5 ps-2" (click)="[1,2].includes(element.idEstado) ? seleccionar(element) : return;"> {{element.fechaFin | date :
                    'mediumDate':'':translate.currentLang}} </td>
            </ng-container>
            <ng-container matColumnDef="diasB">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> DIAS ACUM </th>
                <td mat-cell *matCellDef="let element" class="w5 pe-2 text-end" (click)="[1,2].includes(element.idEstado) ? seleccionar(element) : return;"> {{element.diasBaja | number }} </td>
            </ng-container>
            <ng-container matColumnDef="dias">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w5 ps-2"> DIAS PREV </th>
                <td mat-cell *matCellDef="let element" class="w5 pe-2 text-end" (click)="[1,2].includes(element.idEstado) ? seleccionar(element) : return;"> {{element.dias | number}} </td>
            </ng-container>
            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-2"> {{ 'FORM.OBSERVACIONES' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="ps-2 text-start" (click)="[1,2].includes(element.idEstado) ? seleccionar(element) : return;"> {{element.observaciones}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{ 'selected': row === selectedRow}"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>