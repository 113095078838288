<div class="container text-alcoa">
  <div class="row border-bottom border-alcoa">
    <h1 class="col-12 text-alcoa">{{titulo}}</h1>
  </div>
  <div class="row pt-2">
    <app-buscadorempleado (newItemEvent)="buscarEmpleado($event)" [verLimpiar]="!esUsuario && !esRevision" [idUsuario]="idUsuario"></app-buscadorempleado>
  </div>
  <form id="ayudasFrm" name="ayudasFrm" class="needs-validation pt-3" novalidate *ngIf="empleado.idUsuario">
    <div class="row border-bottom border-alcoa">
      <h2 class="col-12">Nuevo</h2>
    </div>
    <div class="form-group row pt-1">
      <label class="col-lg-2 col-md-3 col-4 col-form-label col-form-label-sm fw-bold" for="tiposAyuda" id="lblAyuda">{{ 'FORM.AYUDA' | translate }}:</label>
      <div class="col-lg-3 col-md-9 col-8">
        <select class="form-control form-control-sm" id="tiposAyuda" name="tiposAyuda" [(ngModel)]="tipoAyudaSel" [ngClass]="!valido && !presupuesto.idTipoAyuda ? 'obligatorio' : '' "
          (ngModelChange)="seleccionTipo()" [compareWith]="compareTipo">
          <option selected></option>
          <option [ngValue]="tipo" *ngFor="let tipo of tipoAyudas">{{ tipo.descripcion }}</option>
        </select>
      </div>
      <label class="col-lg-2 col-md-3 col-4 mt-1 mt-lg-0 col-form-label col-form-label-sm fw-bold" for="beneficiario" id="lblBeneficiario">{{ 'FORM.BENEFICIARIO' | translate }}:</label>
      <div class="col-lg-5 col-md-9 col-8 mt-1 mt-lg-0">
        <select class="form-control form-control-sm" id="beneficiario" #beneficiario="ngModel" name="beneficiario" [(ngModel)]="presupuesto.idBeneficiario"
          [ngClass]="!valido && beneficiario.hasError('required') ? 'obligatorio' : '' " [disabled]="!presupuesto.idTipoAyuda || !beneficiarios" [required]="esUsuario && esSolicitud">
          <option selected></option>
          <option [ngValue]="beneficiario.idBeneficiario" *ngFor="let beneficiario of beneficiarios">{{ beneficiario.nombre }} {{beneficiario.apellidos}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row pt-1">
      <label for="importe" class="col-lg-2 col-md-3 col-4 col-form-label col-form-label-sm fw-bold">{{ 'FORM.IMPORTE' | translate }}:</label>
      <div class="col-lg-3 col-md-5 col-8">
        <input type="number" class="form-control form-control-sm" id="importe" #importe="ngModel" [(ngModel)]="presupuesto.importe" name="importe" min="0" (ngModelChange)="onFocusOutImporte($event)"
          [ngClass]="!valido && importe.hasError('required') ? 'obligatorio' : '' " [required]="!esSolicitud" [disabled]="esSolicitud">
      </div>
    </div>
    <div class="form-group row pt-1" *ngIf="!esSolicitud">
      <div class="input-group mb-3 offset-md-1 offset-lg-2">
        <div class="custom-file col-9">
          <input class="form-control form-control-sm" type="file" #file="ngModel" id="file" name="file" [ngModel]="presupuesto.nombreFichero" (change)="subirFichero($event.target.files)"
            [ngClass]="!valido && !fileToUpload && file.hasError('required') ? 'obligatorio' : '' " language="translate.currentLang" [required]="!esSolicitud">
        </div>
        <label *ngIf="!presupuesto.idPresupuestoAdjunto" class="col-3 ps-2 pt-1">Max. 5 Mb</label>
        <span *ngIf="presupuesto.idPresupuestoAdjunto" class="col-3 ps-2">
          <button class="btn btn-sm btn-alcoa-transparente me-2" (click)="verFichero(ayuda)">{{presupuesto.nombreFichero}}</button><button class="btn btn-sm text-danger"
            (click)="borrarFichero()"><fa-icon [icon]="faTimes"></fa-icon></button>
        </span>
      </div>
    </div>
    <div class="row pt-1">
      <div class="col-12">
        <button type="button" class="btn btn-alcoa float-end ms-2" (click)="altaPresupuesto()">
          <fa-icon [icon]="faSave"></fa-icon> {{ 'BOTONES.GUARDAR' | translate }}
        </button>
        <button type="button" class="btn btn-alcoa float-end" (click)="limpiarPresupuesto()">
          <fa-icon [icon]="faEraser"></fa-icon> {{ 'BOTONES.LIMPIAR' | translate }}
        </button>
      </div>
    </div>
  </form>
  <div class="pt-3" style="width:100%; overflow: auto" [hidden]="!empleado.idUsuario">
    <div class="row border-bottom border-alcoa">
      <h2 class="col-12">Histórico</h2>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="table table-sm table-striped table-bordered table-light text-alcoa align-middle pt-3">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id</th>
        <td mat-cell *matCellDef="let element" class="ps-3 w5" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.idPresupuesto }} </td>
      </ng-container>
      <ng-container matColumnDef="ayuda">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="ps-3 w15" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.descTipoAyuda }} </td>
      </ng-container>
      <ng-container matColumnDef="beneficiario">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="ps-3 w20" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.beneficiario }} </td>
      </ng-container>
      <ng-container matColumnDef="importe">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="text-end pe-3 w10" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.importe |
          currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
      </ng-container>
      <ng-container matColumnDef="acumulado">
        <th mat-header-cell *matHeaderCellDef> Facturado </th>
        <td mat-cell *matCellDef="let element" class="text-end pe-3 w10" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.facturado |
          currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
      </ng-container>
      <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="text-end pe-3" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.observaciones }} </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.estado | translate }} <fa-icon
            [icon]="getIconoEstado(element)" [ngClass]="getClaseEstado(element)"></fa-icon></td>
      </ng-container>
      <ng-container matColumnDef="adjunto">
        <th mat-header-cell *matHeaderCellDef class="w5"></th>
        <td mat-cell *matCellDef="let element" class="w5 text-center">
          <button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" (click)="verFichero(element)" *ngIf="element.nombreFichero">
            <fa-icon [icon]="faPaperClip"></fa-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="borrar">
        <th mat-header-cell *matHeaderCellDef class="w5"></th>
        <td mat-cell *matCellDef="let element" class="w5 text-center">
          <button *ngIf="(element.idEstado == 0 || (element.idEstado == 1 && !esUsuario))" class="btn btn-sm btn-danger " style="font-size:10px;" (click)="confirmarBorrarPresupuesto(element)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{ 'selected': row === selectedRow, 'denegado': row.idEstado == 2 }"> </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
  </div>
</div>
