import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Empleado } from 'src/app/model/empleado';

@Component({
  selector: 'app-revisardialog',
  templateUrl: './revisardialog.component.html',
  styleUrls: ['./revisardialog.component.css']
})
export class RevisardialogComponent implements OnInit {
  idUsuario: number;

  constructor(public dialogRef: MatDialogRef<RevisardialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Empleado) { }

  ngOnInit(): void {
    this.idUsuario = this.data.idUsuario;
  }

}
