import { DatePipe, FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faDoorClosed, faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Aviso } from 'src/app/model/aviso';
import { Ayuda } from 'src/app/model/ayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import { CierresService } from 'src/app/services/cierres.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CierreComponent implements OnInit {
  faDoorClosed = faDoorClosed;
  faLock = faLock;

  mes: number;
  fechaCierre: Date;

  // Tabla
  dataSource: MatTableDataSource<Ayuda>;
  displayColumns: string[] = ['fecha', 'empleado', 'ayuda', 'beneficiario', 'importe', 'observaciones'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService, private cierresService: CierresService, public dialog: MatDialog, public translate: TranslateService, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.cierresService.getMesCierre().subscribe(result => {
      this.mes = result;
      this.fechaCierre = new Date();
      this.fechaCierre.setMonth(this.mes - 1);
    }, error => console.error(error));
    this.ayudasService.getAyudasMes(0, 0).subscribe(result => {
      console.log(result);
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  confirmarCerrarMes(): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a realizar el cierre del mes de ${this.datePipe.transform(this.fechaCierre, 'MMMM', '', this.translate.currentLang)}. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cerrarMes();
      }
    });
  }

  cerrarMes(): void {
    this.cierresService.cerrarMes(this.mes).subscribe(result => {
      this.ayudasService.getAyudasMes(0, 0).subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }, error => console.error(error));
  }

}
