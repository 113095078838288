import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faCheck, faExclamationTriangle, faFile, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Aviso } from 'src/app/model/aviso';
import { IncapacidadTemporal } from 'src/app/model/incapacidadtemporal';
import { BajasService } from 'src/app/services/bajas.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { BajadialogComponent } from './bajadialog/bajadialog.component';

@Component({
  selector: 'app-revisarbajas',
  templateUrl: './revisarbajas.component.html',
  styleUrls: ['./revisarbajas.component.css']
})
export class RevisarbajasComponent implements OnInit {
  faCheck = faCheck;
  faTimes = faTimes;
  faPaperClip = faPaperclip;
  faFile = faFile;

  // Tabla
  dataSource: MatTableDataSource<IncapacidadTemporal>;
  displayColumns: string[] = ['nombre', 'login', 'expediente', 'fechaIni', 'fechaFin', 'adjunto', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: IncapacidadTemporal;

  constructor(public bajasService: BajasService, private datePipe: DatePipe, public translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buscarBajasRevisar();
  }

  buscarBajasRevisar(): void {
    this.bajasService.getBajasRevisar().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  verFichero(id: number): void {
    this.bajasService.getBajaAdjunto(id).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  editar(it: IncapacidadTemporal): void {
    const dialogRef = this.dialog.open(BajadialogComponent, {
      width: "1200px",
      disableClose: true,
      data: it
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarBajasRevisar();
    });
  }

  confirmarBorrar(it: IncapacidadTemporal): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a denegar la incapacidad temporal del día ${this.datePipe.transform(it.fechaInicio, 'dd/MM/yyyy')}. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      disableClose: true,
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        it.idEstado = 3;
        this.bajasService.updateBajaUsuario(it, null).subscribe(result => {
          this.buscarBajasRevisar();
        });
      }
    });
  }

  aceptar(baja: IncapacidadTemporal): void {
    if (baja.fechaFin) {
      baja.idEstado = 4;
    } else {
      baja.idEstado = 2;
    }
    this.bajasService.updateBajaUsuario(baja, null).subscribe(result => {
      this.buscarBajasRevisar();
    });
  }
}
