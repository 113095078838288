export class Coeficiente {
    public idAyudaEstudiosCoeficiente: number;
    public hijos: number;
    public coeficiente: number;
    public fechaInicio: Date;
    public fechaFin: Date;

    public editable: boolean = false;

    constructor() {}
}