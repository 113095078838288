<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">{{ 'AYUDAS.AYUDAS_TITULO' | translate }}</h1>
    </div>
    <div class="row pt-2">
        <app-buscadorempleado (newItemEvent)="buscarEmpleado($event)" [verLimpiar]="!esUsuario && !esRevision" [idUsuario]="idUsuario"></app-buscadorempleado>
    </div>

    <form id="ayudasFrm" name="ayudasFrm" class="needs-validation pt-3" novalidate *ngIf="empleado.idUsuario" (keyup.enter)="(!esRevision && !ayuda.mesCierre && ((ayuda.estado == 0 && esUsuario) || (ayuda.estado == 1 && !esUsuario) || !ayuda.idAyuda)) ? altaAyuda() : return">
        <div class="row border-bottom border-alcoa">
            <h2 class="col-12">{{ 'BOTONES.NUEVA_AYUDA' | translate }}</h2>
        </div>
        <div class="form-group row pt-1">
            <label id="lblFecha" for="fechaIni" class="col-lg-1 col-md-2 col-4 align-self-center col-form-label col-form-label-sm fw-bold">{{ 'FORM.FECHA' | translate }}:</label>
            <div class="col-md-4 col-7">
                <input matInput [matDatepicker]="picker"  class="text-alcoa text-center form-control form-control-sm form-control-calendar" (click)="picker.open()" [formControl]="date" readonly>
                <mat-datepicker #picker [disabled]="esUsuario"></mat-datepicker>
            </div>
        </div>
        <div class="form-group row pt-1">
            <label class="col-lg-1 col-md-2 col-4 col-form-label col-form-label-sm fw-bold" for="tiposAyuda" id="lblAyuda">{{ 'FORM.AYUDA' | translate }}:</label>
            <div class="col-lg-2 col-md-10 col-8">
                <select class="form-control form-control-sm" id="tiposAyuda" name="tiposAyuda" [(ngModel)]="ayuda.idTipoAyuda" (change)="filtrarAyudas()" [ngClass]="!valido && !ayuda.idTipoAyuda ? 'obligatorio' : '' " [disabled]="esRevision && !ayuda.idAyuda">
                    <option selected></option>
                    <option [ngValue]="tipo.idTipoAyudaContrato" *ngFor="let tipo of tipoAyudas">{{ tipo.descripcion }}</option>
                </select>
            </div>
            <label class="col-lg-2 col-md-2 col-4 mt-lg-0 mt-1 col-form-label col-form-label-sm fw-bold" for="beneficiario" id="lblBeneficiario">{{  'FORM.BENEFICIARIO' | translate }}:</label>
            <div class="col-lg-4 col-md-10 col-8 mt-lg-0 mt-1">
                <select class="form-control form-control-sm" id="beneficiario" name="beneficiario" #beneficiario="ngModel" [(ngModel)]="ayuda.idBeneficiario" (change)="filtrarAyudas()"
                [disabled]="(esRevision && !ayuda.idAyuda) || !beneficiarios" [ngClass]="!valido && beneficiario.hasError('required') && !ayuda.idBeneficiario ? 'obligatorio' : '' " [required]="tipoAyudaSel?.idTipoAyuda == 4 || (esCampamento && esUsuario)">
                    <option selected></option>
                    <option [ngValue]="beneficiario.idBeneficiario" *ngFor="let beneficiario of beneficiarios">{{ beneficiario.nombre }} {{beneficiario.apellidos}}</option>
                </select>
            </div>
        </div>
        <div class="form-group row pt-1" *ngIf="tipoAyudaSel?.idTipoAyuda == 4">
            <label for="estudio" class="col-lg-1 col-md-2 col-4 col-form-label col-form-label-sm fw-bold">Estudios:</label>
            <div class="col-lg-2 col-md-4 col-8">
                <select class="form-control form-control-sm" id="estudio" name="estudio" [(ngModel)]="ayuda.idCurso" [required]="tipoAyudaSel?.idTipoAyuda == 4" (change)="seleccionarEstudios()"
                [ngClass]="!valido && tipoAyudaSel?.idTipoAyuda == 4 && !ayuda.idCurso ? 'obligatorio' : '' ">
                    <option selected></option>
                    <option [ngValue]="curso.idCurso" *ngFor="let curso of cursos">{{ curso.nombre }}</option>
                </select>
            </div>
            <label for="curso" class="col-lg-1 col-md-2 col-4 col-form-label col-form-label-sm fw-bold" *ngIf="empleado.idPlanta == 12">Curso:</label>
            <div class="col-lg-2 col-md-4 col-8" *ngIf="empleado.idPlanta == 12">
                <input type="text" class="form-control form-control-sm" id="curso" name="curso" [(ngModel)]="ayuda.curso" [ngClass]="!valido && tipoAyudaSel?.idTipoAyuda == 4 && (ayuda.curso?.length == 0 || !ayuda.curso) ? 'obligatorio' : '' ">
            </div>
            
        </div>
        <div class="form-group row pt-1">
            <label for="importe" class="col-lg-1 col-4 col-md-2 col-form-label col-form-label-sm fw-bold">{{ 'FORM.IMPORTE' | translate }}:</label>
            <div class="col-lg-2 col-md-3 col-8">
                <input type="number" class="form-control form-control-sm" id="importeFactura" [(ngModel)]="ayuda.importeFactura" (ngModelChange)="onFocusOutImporte()"
                    name="importeFactura" min="0" [ngClass]="!valido && (!ayuda.importeFactura || ayuda.importeFactura == 0) ? 'obligatorio' : '' " [disabled]="esRevision && !ayuda.idAyuda">
            </div>
            <label for="importe" class=" col-lg-2 col-md-3 col-4 col-form-label col-form-label-sm fw-bold" *ngIf="!esUsuario">{{'FORM.IMPORTE_AYUDA' | translate }}:</label>
            <div class="col-lg-2 col-md-4 col-8" *ngIf="!esUsuario">
                <input type="number" class="form-control form-control-sm" id="importe" [(ngModel)]="ayuda.importe"
                    name="importe" min="0" [ngClass]="!valido && (!ayuda.importe || ayuda.importe == 0) ? 'obligatorio' : '' " [disabled]="tipoAyudaSel == null || tipoAyudaSel.porcentajeAyuda != null">
            </div>
            <label class="col-lg-2 col-md-2 col-4 col-form-label  mt-lg-0 mt-1 col-form-label-sm fw-bold">Presupuesto:</label>
            <div class="col-lg-3 col-md-5 col-8 mt-lg-0 mt-1">
                <select class="form-control form-control-sm" [(ngModel)]="presupuestoSel" name="presupuesto" #presupuesto="ngModel" (ngModelChange)="presupuestoChange($event)"
                  [ngClass]="!valido && presupuesto.hasError('required') ? 'obligatorio' : '' " [disabled]="(esRevision && !ayuda.idAyuda) || presupuestos.length == 0" [required]="esCampamento">
                    <option selected></option>
                    <option [ngValue]="presupuesto" *ngFor="let presupuesto of presupuestos">{{ presupuesto.idPresupuesto}} - {{ presupuesto.descTipoAyuda}}</option>
                </select>
            </div>
        </div>
        <div class="form-group row pt-1">
            <label for="alerta" class="text-danger col-12" *ngIf="esUsuario && presupuestoSel && !valido && (ayuda.importeFactura > presupuestoSel.importe - presupuestoSel.facturado)">El importe supera la cantidad restante del presupuesto. Solicitado = {{presupuestoSel.importe}}. Restante = {{presupuestoSel.importe - presupuestoSel.facturado}}</label>
        </div>
        <div class="form-group row pt-1" *ngIf="!esUsuario && presupuestoSel">
            <label for="alerta" class="text-danger col-lg-5" *ngIf="!valido && (ayuda.importeFactura > presupuestoSel.importe - presupuestoSel.facturado)">El importe supera la cantidad restante del presupuesto</label>
            <label for="importe" class="col-lg-2 col-md-3 col-sm-4 col-form-label col-form-label-sm fw-bold" [ngClass]="!valido && (ayuda.importeFactura > presupuestoSel.importe - presupuestoSel.facturado) ? '' : 'offset-lg-5'">Cantidad:</label>
            <div class="col-lg-1 col-md-5 col-sm-8">
                <input type="number" class="form-control form-control-sm" id="cantidadPrespuesto" name="cantidadPresupuesto" [(ngModel)]="presupuestoSel.importe" readonly>
            </div>
            <label for="importe" class="col-lg-2 col-md-3 col-sm-4 col-form-label col-form-label-sm fw-bold">Importe Facturado:</label>
            <div class="col-lg-1 col-md-5 col-sm-8">
                <input type="number" class="form-control form-control-sm" id="importeFacturado" [(ngModel)]="presupuestoSel.facturado" name="importeFacturado" readonly>
            </div>
            <div class="col-1">
                <button class="btn btn-sm btn-alcoa-transparente" (click)="verFicheroPresupuesto()"><fa-icon [icon]="faPaperClip"></fa-icon></button>
            </div>
        </div>
        <div class="form-group row pt-1" *ngIf="!esUsuario">
            <div class="col-lg-2 col-md-3 col-4">
                <label class="col-form-label col-form-label-sm fw-bold" for="observaciones">{{ 'FORM.OBSERVACIONES' | translate }}:</label>
            </div>
            <div class="col-lg-10 col-md-9 col-8">
                <textarea class="form-control form-control-sm" id="observaciones" name="observaciones" [(ngModel)]="ayuda.observaciones" rows="4" [ngClass]="!valido && !ayuda.observaciones && esRevision ? 'obligatorio' : '' " [disabled]="esRevision && !ayuda.idAyuda"></textarea>
            </div>
        </div>
        <div class="form-group row pt-1">
            <div class="input-group mb-3 offset-md-1">
                <div class="custom-file col-9">
                    <input class="form-control form-control-sm" type="file" #file (change)="subirFichero($event.target.files)"
                    [ngClass]="!valido && !fileToUpload && !ayuda.idAyudaAdjunto ? 'obligatorio' : '' " [disabled]="esRevision && !ayuda.idAyuda" language="translate.currentLang">
                </div>
                <label *ngIf="!ayuda.idAyudaAdjunto" class="col-3 ps-2 pt-1">Max. 5 Mb</label>
                <span *ngIf="ayuda.idAyudaAdjunto" class="col-3 ps-2">
                    <button class="btn btn-sm btn-alcoa-transparente me-2" (click)="verFichero(ayuda)">{{ayuda.nombreFichero}}</button>
                </span>
            </div>
        </div>
        <div class="row pt-1" *ngIf="!esRevision && !ayuda.mesCierre && ((ayuda.estado == 0 && esUsuario) || (ayuda.estado == 1 && !esUsuario) || !ayuda.idAyuda)">
            <div class="col-12">
                <button type="button" class="btn btn-alcoa float-end ms-2" (click)="altaAyuda()">
                    <fa-icon [icon]="faSave"></fa-icon> {{ 'BOTONES.GUARDAR' | translate }}
                </button>
                <button type="button" class="btn btn-alcoa float-end" (click)="limpiarAyuda()">
                    <fa-icon [icon]="faEraser"></fa-icon> {{ 'BOTONES.LIMPIAR' | translate }}
                </button>
            </div>
        </div>
        <div class="row pt-1" *ngIf="(esRevision || !esUsuario) && !ayuda.mesCierre && ayuda.estado == 0">
            <div class="col-12">
                <button type="button" class="btn btn-danger float-end ms-2" (click)="denegar()" [disabled]="!ayuda.idAyuda">
                    <fa-icon [icon]="faTimes"></fa-icon> Denegar
                </button>
                <button type="button" class="btn btn-success float-end" (click)="revisar()" [disabled]="!ayuda.idAyuda">
                    <fa-icon [icon]="faCheck"></fa-icon> Aprobar
                </button>
            </div>
        </div>
    </form>
    <div class="pt-3" style="width:100%; overflow: auto" [hidden]="!empleado.idUsuario">
        <table mat-table [dataSource]="dataSource" matSort
            class="table table-sm table-striped table-bordered table-light text-alcoa align-middle pt-3">
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FORM.FECHA' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="w10" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{element.fecha | date : 'mediumDate':'':translate.currentLang}} </td>
            </ng-container>
            <ng-container matColumnDef="ayuda">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.descTipoAyuda }} </td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w20" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.beneficiario }} </td>
            </ng-container>
            <ng-container matColumnDef="importeFactura">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w7" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.importeFactura | currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> Importe Ayuda </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w7" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.importe | currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.observaciones }} {{element.estado == 0 ? "Pendiente Revisar People Service" : ""}} {{element.estudio}} {{element.curso}} </td>
            </ng-container>
            <ng-container matColumnDef="nomina">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.MES_NOMINA' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w7" (click)="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario)) ? seleccionarAyuda(element) : return;"> {{ element.mesCierre | number:'2.0-0' }}{{ element.mesCierre ? '/':'' }}{{ element.anoCierre }} </td>
            </ng-container>
            <ng-container matColumnDef="adjunto">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" (click)="verFichero(element)" *ngIf="element.nombreFichero">
                        <fa-icon [icon]="faPaperClip"></fa-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button *ngIf="!element.mesCierre && (element.estado == 0 || (element.estado == 1 && !esUsuario))" class="btn btn-sm btn-danger " style="font-size:10px;" (click)="confirmarBorrarAyuda(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{ 'selected': row === selectedRow, 'denegado': row.estado == 2 }"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
