<div class="container-fluid text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Informes</h1>
    </div>
    <form class="row pt-2">
        <div class="col-2">
            <mat-form-field class="form-control">
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" >
                    <input matStartDate formControlName="start" [placeholder]="'FORM.FECHA_INICIO' | translate" readonly>
                    <input matEndDate formControlName="end" [placeholder]="'FORM.FECHA_FIN' | translate" readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="col-2">
            <label class="form-label fw-bold">Estado</label>
            <mat-select class="form-control" [(ngModel)]="estado" name="estado">
                <mat-option></mat-option>
                <mat-option [value]="estado.value" *ngFor="let estado of estados">{{ estado.label | translate }}</mat-option>
            </mat-select>
        </div>
        <div class="col-1">
            <label class="form-label fw-bold">Expediente</label>
            <input type="number" class="form-control" [(ngModel)]="expediente" name="expediente">
        </div>
        <div class="col-4">
            <label class="form-label fw-bold">Nombre</label>
            <input type="text" class="form-control" [(ngModel)]="nombre" name="nombre">
        </div>
        <div class="col-1">
            <button type="button" class="btn btn-alcoa float-end mb-1" (click)="buscar()"><fa-icon [icon]="faSearch"></fa-icon> {{ 'BOTONES.BUSCAR' | translate }}</button>
            <button type="button" class="btn btn-alcoa float-end" (click)="limpiar()"><fa-icon [icon]="faEraser"></fa-icon> {{ 'BOTONES.LIMPIAR' | translate }}</button>
        </div>
    </form>
    <div class="row">
        <div class="col-1 text-start">
            <button class="btn btn-excel" (click)="exportar()"><fa-icon [icon]="faExcel"></fa-icon></button>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle mt-3">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w10"> {{ 'FORM.FECHA' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="w10"> {{element.fecha | date : 'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="ayuda">
            <th mat-header-cell *matHeaderCellDef class="w15"> {{'FORM.AYUDA' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.descTipoAyuda }} </td>
        </ng-container>
        <ng-container matColumnDef="contrato">
            <th mat-header-cell *matHeaderCellDef> {{'MODAL.TIPO_CONTRATO' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w10">{{element.convenio}}</td>
        </ng-container>
        <ng-container matColumnDef="expediente" class="w5">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.EXPEDIENTE' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w5"> {{ element.expediente }} </td>
        </ng-container>
        <ng-container matColumnDef="empleado">
            <th mat-header-cell *matHeaderCellDef class="w20"> {{'FORM.EMPLEADO' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w20"> {{ element.empleado }} </td>
        </ng-container>
        <ng-container matColumnDef="beneficiario">
            <th mat-header-cell *matHeaderCellDef class="w20"> {{'FORM.BENEFICIARIO' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w20"> {{ element.beneficiario }} </td>
        </ng-container>
        <ng-container matColumnDef="fechaNac">
            <th mat-header-cell *matHeaderCellDef class="w10"> {{'FORM.FECHA_NACIMIENTO' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.fechaNac }} </td>
        </ng-container>
        <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef class="w10"> {{'FORM.IMPORTE' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.importe | number:'1.2-2' }} </td>
        </ng-container>
        <ng-container matColumnDef="observaciones">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3"> {{element.observaciones }} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef class="w10"> Estado </th>
            <td mat-cell *matCellDef="let element" class="pe-3 w10 text-end"> {{ element.estado | translate }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
</div>
