<h2 mat-dialog-title class="text-alcoa border-bottom border-alcoa-gris"> Seleccionar Personal</h2>
<mat-dialog-content class="mat-typography text-alcoa">
    <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'FORM.NOMBRE' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.nombre }} </td>
        </ng-container>
        <ng-container matColumnDef="login">
            <th mat-header-cell *matHeaderCellDef> Login </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w30"> {{ element.login }} </td>
        </ng-container>
        <ng-container matColumnDef="expediente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'FORM.EXPEDIENTE' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.expediente}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;" (click)="seleccionarEmpleado(row)"> </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions align="end" class="border-top border-alcoa-gris">
    <button mat-dialog-close class="btn btn-secondary" cdkFocusInitial>
        <fa-icon [icon]="faDoorClosed"></fa-icon> Cancelar
    </button>
</mat-dialog-actions>
