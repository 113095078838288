export class Ayuda {
    public idAyuda: number;
    public idTipoAyuda: number;
    public descTipoAyuda: string;
    public idUsuario: number;
    public expediente: string;
    public empleado: string;
    public idBeneficiario: number;
    public beneficiario: string;
    public fecha: Date;
    public fechaStr: string;
    public importe: number;
    public importeFactura: number;
    public observaciones: string;
    public mesCierre: number;
    public anoCierre: number;
    public idPresupuesto: number;
    public codigoNomina: string;
    public planta: string;
    public convenio: string;
    public mesNomina: string;
    public nombreFichero: string;
    public idAyudaAdjunto: number;
    public estado: number;
    public idCurso: number;
    public curso: string;
    public estudio: string;

    constructor() {}
}