import { Component, Inject, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes, faSearch, faEraser, faPlus, faMinus, faSave, faPaperclip, faCheck, faFile, faEye, faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { IncapacidadTemporal } from 'src/app/model/incapacidadtemporal';
import { BajasService } from 'src/app/services/bajas.service';

@Component({
  selector: 'app-bajadialog',
  templateUrl: './bajadialog.component.html',
  styleUrls: ['./bajadialog.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BajadialogComponent implements OnInit {
  faTimes = faTimes;
  faSearch = faSearch;
  faEraser = faEraser;
  faPlus = faPlus;
  faMinus = faMinus;
  faSave = faSave;
  faPaperClip = faPaperclip;
  faCheck = faCheck;
  faFile = faFile;
  faEye = faEye;
  faDoorClosed = faDoorClosed;

  baja: IncapacidadTemporal;
  hoy = moment();

  constructor(public dialogRef: MatDialogRef<BajadialogComponent>, private bajasService: BajasService, @Inject(MAT_DIALOG_DATA) public data: IncapacidadTemporal) { }

  ngOnInit(): void {
    this.baja = JSON.parse(JSON.stringify(this.data));
  }

  submitBaja(form: NgForm): void {
    if (form.valid) {
      this.bajasService.updateBajaUsuario(this.baja, null).subscribe(result => {
        this.dialogRef.close();
      });
    }
  }

  clearDate(event: NgModel) {
    event.control.setValue(null);
  }

}
