export class Empleado {
    public idPersonal: number;
    public idUsuario: number;
    public idWorkDay: number;
    public idNegocio: number;
    public idAreaNegocio: number;
    public idCentro: number;
    public idPlanta: number;
    public idDpto: number;
    public dpto: string;
    public idTipoPersonal: number;
    public login: string;
    public expediente: string;
    public nombre: string;
    public apel1: string;
    public apel2: string;
    public nombre1: string;
    public fecAlta: Date;
    public fecBaja: Date;
    public fecPues: Date;
    public estado: string;
    public idUsuarioEnter: number;
    public fecIntro: Date;
    public idPuestoPot: number;
    public fecPuesPot: Date;
    public fecCambios: Date;
    public idDptoPot: number;
    public email: string;
    public idContractType: number;
    public idWdDpto: number;
    public idWdPuesto: number;
    public estadoWd: string;
    
    public idJornada: number;
    public jornada: string;
    public idTipoContrato: number;
    public tipoContrato: string;
    public seccion: string;

    constructor() {}
}