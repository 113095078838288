import { Coeficiente } from "./coeficiente";

export class Curso {
    idAyudaEstudiosCurso: number;
    idCurso: number;
    nombre: string;
    importe: number;
    idTipoContrato: number;
    tipoContrato: string;
    docOpcional: boolean;
}

export class CursoCoeficiente {
    public curso: Curso;
    public coeficientes: Coeficiente[];
}