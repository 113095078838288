<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Ayudas a revisar</h1>
    </div>
    <div class="pt-2" style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="ayuda">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15" (click)="validar(element)"> {{ element.descTipoAyuda }} </td>
            </ng-container>
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.FECHA' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="validar(element)"> {{ element.fecha | date : 'mediumDate':'':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="expediente">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.EXPEDIENTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="validar(element)">{{element.expediente}}</td>
            </ng-container>
            <ng-container matColumnDef="empleado">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.EMPLEADO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w25" (click)="validar(element)">{{element.empleado}}</td>
            </ng-container>
            <ng-container matColumnDef="contrato">
                <th mat-header-cell *matHeaderCellDef> {{'MODAL.TIPO_CONTRATO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="validar(element)">{{element.convenio}}</td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="validar(element)"> {{ element.beneficiario }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10" (click)="validar(element)"> {{ element.importe | currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>             
            <ng-container matColumnDef="adjunto">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" (click)="verFichero(element)" *ngIf="element.nombreFichero">
                        <fa-icon [icon]="faPaperClip"></fa-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef class="w10"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center p-0">
                    <button *ngIf="(element.estado == 0 )" class="btn btn-sm btn-success me-1" style="font-size:10px;" (click)="confirmarAprobar(element)">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button *ngIf="(element.estado == 0 )" class="btn btn-sm btn-danger " style="font-size:10px;" (click)="confirmarDenegar(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

    
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
