import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faFileExcel, faSearch, faServer, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { IncapacidadTemporal } from 'src/app/model/incapacidadtemporal';
import { BajasService } from 'src/app/services/bajas.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-informebajas',
  templateUrl: './informebajas.component.html',
  styleUrls: ['./informebajas.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class InformebajasComponent implements OnInit {
  faTimes = faTimes;
  faSearch = faSearch;
  faFileExcel = faFileExcel;

  rangoFecha = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  actuales = true;

  // Tabla
  dataSource: MatTableDataSource<IncapacidadTemporal>;
  displayColumns: string[] = ['tipoContrato', 'jornada', 'expediente', 'nombre', 'departamento', 'seccion', 'tipo', 'fechaIni', 'fechaFin', 'diasB', 'dias', 'observaciones']
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: IncapacidadTemporal;

  constructor(public bajasService: BajasService, private datePipe: DatePipe, public translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.bajasService.getBajasActivas().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearDate(event: UntypedFormGroup) {
    event.controls.start.setValue(null);
    event.controls.end.setValue(null);
  }

  buscar(): void {
    if (this.actuales) {
      this.bajasService.getBajasActivas().subscribe(result => {
        this.dataSource.data = result;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource = this.dataSource;
      });
    } else {
      let parametros: HttpParams = new HttpParams();
      if (this.rangoFecha.controls.start.value) {
        parametros = parametros.append("fechaIni", this.datePipe.transform(this.rangoFecha.controls.start.value, "yyyy-MM-dd"));
      }
      if (this.rangoFecha.controls.end.value) {
        parametros = parametros.append("fechaFin", this.datePipe.transform(this.rangoFecha.controls.end.value, "yyyy-MM-dd"));
      }
      this.bajasService.getBajasFormulario(parametros).subscribe(result => {
        this.dataSource.data = result;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource = this.dataSource;
      });
    }
  }

  exportar(): void {
    if (this.dataSource != undefined) {
      var prepare = [];
      this.dataSource.data.forEach(a => {
        var tempObj = [];
        tempObj.push(a.usuario.tipoContrato);
        tempObj.push(a.usuario.jornada);
        tempObj.push(a.usuario.expediente);
        tempObj.push(a.usuario.nombre);
        tempObj.push(a.usuario.dpto);
        tempObj.push(a.usuario.seccion);
        tempObj.push(a.tipo);
        tempObj.push(this.datePipe.transform(a.fechaInicio, "dd-MM-yyyy"));
        tempObj.push(this.datePipe.transform(a.fechaFin, "dd-MM-yyyy"));
        tempObj.push(a.diasBaja);
        tempObj.push(a.dias);
        prepare.push(tempObj);
      });
      const workSheet = XLSX.utils.json_to_sheet([]);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();

      //Had to create a new workbook and then add the header
      var Heading = [['NIV', 'Jornada', 'Expediente', 'Nombre', 'Departamento', 'Seccion', 'Tipo I.T.', 'Fecha Inicio', 'Fecha Fin', 'Dias Acum.', 'Dias Prev.']];
      XLSX.utils.sheet_add_aoa(workSheet, [['Alcoa | I.T.']], {origin: 'D1', cellStyles: true});
      XLSX.utils.sheet_add_aoa(workSheet, Heading, {origin: 'A2'});

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, prepare, { origin: 'A3', skipHeader: true });

      XLSX.utils.book_append_sheet(workBook, workSheet, 'InformeBajas');
      XLSX.writeFile(workBook, 'InformeBajas.xlsx');
    }
  }
}
