import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { faPen, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Coeficiente } from 'src/app/model/coeficiente';
import { Curso } from 'src/app/model/curso';
import { LabelValue } from 'src/app/model/labelvalue';
import { AyudasService } from 'src/app/services/ayudas.service';
import { EstudiosdialogComponent } from './estudiosdialog/estudiosdialog.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-estudios',
  templateUrl: './estudios.component.html',
  styleUrls: ['./estudios.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EstudiosComponent implements OnInit {
  faPenSquare = faPen;
  faPlus = faPlus;
  faTimes = faTimes;
  faSave = faSave;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ayudaEstudiosCursos: Curso[] = [];
  coeficientes: Coeficiente[] = [];
  cursos: LabelValue[] = [];

  // Tabla
  dataSource: MatTableDataSource<Curso>;
  displayColumns: string[] = ['curso', 'tipoContrato', 'importe', 'importe2', 'importe3', 'importe4'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Curso;

  constructor(private ayudasService: AyudasService, private datePipe: DatePipe, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
    this.buscarCoeficientes();
    this.buscarCursos();
  }

  buscarCursos(): void {
    this.ayudasService.getCursos().subscribe(result => {
      this.cursos = result;
    });
  }

  buscarAyudasEstudiosCurso(): void {
    this.ayudasService.getAyudaEstudios().subscribe(result => {
      this.ayudaEstudiosCursos = result;
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => console.error(error));
  }

  buscarCoeficientes(): void {
    this.coeficientes = [];
    this.ayudasService.getCoeficientes().subscribe(result => {
      for (let h = 2; h < 5; h++) {
        let coef: Coeficiente = new Coeficiente();
        coef.hijos = h;
        coef.coeficiente = 1;

        if (result.some(c => c.hijos == h)) {
          this.coeficientes.push(result.filter(c => c.hijos == h)[0]);
        } else {
          this.coeficientes.push(coef);
        }
      }


      this.buscarAyudasEstudiosCurso();
    }, error => console.error(error));
  }

  anadir(): void {
    let dialogRef = this.dialog.open(EstudiosdialogComponent, {
      width: "800px"
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarCoeficientes();
    });
  }

  editar(curso: Curso): void {
    let dialogRef = this.dialog.open(EstudiosdialogComponent, {
      width: "800px",
      data: curso
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarCoeficientes();
    });
  }

  borrarCurso(curso: LabelValue): void {
    this.ayudasService.deleteCurso(curso.value).subscribe(result => this.buscarCursos());
  }

  addCurso(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      let curso: LabelValue = new LabelValue();
      curso.label = value;
      this.ayudasService.addCurso(curso).subscribe(result => this.buscarCursos());
    }

    // Clear the input value
    event.input!.value = '';
  }

  editarCoeficiente(coeficiente: Coeficiente): void {
    coeficiente.editable = true;
  }

  modificarCoeficiente(coeficiente: Coeficiente): void {
    this.ayudasService.updateCoeficientes(coeficiente).subscribe(result => {
      coeficiente.editable = false;
      this.buscarCoeficientes();
    }, error => console.error(error));
  }

  getImporte(numHijos: number, curso: Curso): number {
    let importeCalculado = curso.importe;

    // Si el curso es Master (id = 10) no se calcula coeficiente
    if (curso.idCurso != 10) {
      switch (numHijos) {
        case 2:
          importeCalculado = curso.importe * this.coeficientes[0].coeficiente;
          break;
        case 3:
          importeCalculado = curso.importe * this.coeficientes[1].coeficiente;
          break;
        case 4:
          importeCalculado = curso.importe * this.coeficientes[2].coeficiente;
          break;
      }
    }

    return importeCalculado;
  }
}
