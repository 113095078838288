import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faDoorClosed, faPen, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Curso } from 'src/app/model/curso';
import { LabelValue } from 'src/app/model/labelvalue';
import { AyudasService } from 'src/app/services/ayudas.service';

@Component({
  selector: 'app-estudiosdialog',
  templateUrl: './estudiosdialog.component.html',
  styleUrls: ['./estudiosdialog.component.css']
})
export class EstudiosdialogComponent implements OnInit {
  faDoorClosed = faDoorClosed;
  faTimes = faTimes;
  faPenSquare = faPen;
  faSave = faSave;

  cursos: LabelValue[] = [];
  cursoSel: LabelValue;
  tiposContrato: LabelValue[] = [];
  tipoContratoSel: LabelValue;

  constructor(public dialogRef: MatDialogRef<EstudiosdialogComponent>, private ayudasService: AyudasService, @Inject(MAT_DIALOG_DATA) public data: Curso) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
    if (this.data) {
      this.cursoSel = new LabelValue();
      this.cursoSel.value = this.data.idCurso;
    } else {
      this.data = new Curso();
    }
    this.ayudasService.getCursos().subscribe(result => this.cursos = result);
    this.ayudasService.getTipoContratos().subscribe(result => this.tiposContrato = result);
    this.tipoContratoSel = new LabelValue();
    this.tipoContratoSel.label = this.data.tipoContrato;
    this.tipoContratoSel.value = this.data.idTipoContrato;
  }

  compareTipos(t1: LabelValue, t2: LabelValue) {
    return t1 && t2 && t1.value === t2.value;
  }

  compareCurso(c1: LabelValue, c2: LabelValue) {
    return c1 && c2 && c1.value === c2.value;
  }

  guardar(form: NgForm): void {
    if (form.valid) {
      this.data.idCurso = this.cursoSel.value;
      this.data.idTipoContrato = this.tipoContratoSel.value;
      this.ayudasService.addAyudaEstudios(this.data).subscribe(result => this.dialogRef.close());
    }
  }

  modificar(): void {
    this.ayudasService.updateAyudaEstudios(this.data).subscribe(result => this.dialogRef.close());
  }

  eliminar(): void {
    this.ayudasService.deleteAyudaEstudios(this.data).subscribe(result => this.dialogRef.close());
  }
}
