import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Aviso } from 'src/app/model/aviso';

@Component({
  selector: 'app-avisodialog',
  templateUrl: './avisodialog.component.html',
  styleUrls: ['./avisodialog.component.css']
})
export class AvisodialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AvisodialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Aviso) { }

  ngOnInit(): void {
  }

}
