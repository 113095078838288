import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faEraser, faFileExcel, faPaperclip, fas, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Ayuda } from 'src/app/model/ayuda';
import { LabelValue } from 'src/app/model/labelvalue';
import { TipoAyudaContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-informeayudas',
  templateUrl: './informeayudas.component.html',
  styleUrls: ['./informeayudas.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class InformeayudasComponent implements OnInit {
  faSearch = faSearch;
  faEraser = faEraser;
  faExcel = faFileExcel;
  faPaperClip = faPaperclip;

  tipoConvenio: number;
  tipoAyuda: number[];
  expediente: number;
  nombre: string;
  tipoContratos: LabelValue[] = [];
  tipoAyudas: TipoAyudaContrato[] = [];

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  // Tabla
  dataSource: MatTableDataSource<Ayuda>;
  displayColumns: string[] = ['fecha', 'ayuda', 'contrato', 'expediente', 'empleado', 'planta', 'beneficiario', 'importe', 'observaciones', 'mes', 'adjunto'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.ayudasService.getTipoContratos().subscribe(result => {
      this.tipoContratos = result;
    }, error => console.error(error));
  }

  buscarTipoAyuda(): void {
    this.ayudasService.getTipoAyudas(this.tipoConvenio).subscribe(result => {
      this.tipoAyudas = result;
    }, error => console.error(error));
  }

  buscar(): void {
    console.log(this.tipoAyuda);
    this.ayudasService.getAyudasInforme(this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd'),
    this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd'), this.tipoConvenio, this.tipoAyuda, this.expediente, this.nombre).subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  limpiar(): void {
    this.range.controls.start.setValue(null);
    this.range.controls.end.setValue(null);
    delete this.nombre;
    delete this.expediente;
    delete this.tipoAyuda;
    delete this.tipoConvenio;
    this.dataSource.data = [];
    this.dataSource = this.dataSource;
  }

  verFichero(ayuda: Ayuda): void {
    this.ayudasService.getAyudasAdjunto(ayuda.idAyudaAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  exportar(): void {
    if (this.dataSource != undefined) {
      var prepare = [];
      this.dataSource.data.forEach(a => {
        var tempObj = [];
        tempObj.push(this.datePipe.transform(a.fecha, "dd-MM-yyyy"));
        tempObj.push(a.descTipoAyuda);
        tempObj.push(a.expediente);
        tempObj.push(a.empleado);
        tempObj.push(a.planta);
        tempObj.push(a.convenio);
        tempObj.push(a.beneficiario);
        tempObj.push(a.importe);
        tempObj.push(a.estado == 0 ? "Pendiente Revisar People Service" : a.observaciones);
        tempObj.push(a.mesNomina);
        prepare.push(tempObj);
      });
      const workSheet = XLSX.utils.json_to_sheet([]);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();



      //Had to create a new workbook and then add the header
      var Heading = [['Fecha', 'Ayuda', 'Expediente', 'Empleado', 'Planta', 'PIC/PFD', 'Beneficiario', 'Importe', 'Observaciones', 'Mes Nomina']];
      XLSX.utils.sheet_add_aoa(workSheet, [['Alcoa | Atenciones Sociales']], {origin: 'E1', cellStyles: true});
      XLSX.utils.sheet_add_aoa(workSheet, Heading, {origin: 'A2'});

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, prepare, { origin: 'A3', skipHeader: true });

      XLSX.utils.book_append_sheet(workBook, workSheet, 'InformeAyudas');
      XLSX.writeFile(workBook, 'InformeAyudas.xlsx');
    }
  }
}
