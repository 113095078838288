import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faTimes, faSearch, faEraser, faPlus, faSave, faPaperclip, faCheck, faExclamationTriangle, faFile, faMinus, faEye } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Aviso } from 'src/app/model/aviso';
import { Empleado } from 'src/app/model/empleado';
import { IncapacidadTemporal, IncapacidadTemporalAdjunto, TipoIncapacidadTemporal } from 'src/app/model/incapacidadtemporal';
import { BajasService } from 'src/app/services/bajas.service';
import { AvisodialogComponent } from '../../dialog/avisodialog/avisodialog.component';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';

@Component({
  selector: 'app-bajas',
  templateUrl: './bajas.component.html',
  styleUrls: ['./bajas.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class BajasComponent implements OnInit {
  faTimes = faTimes;
  faSearch = faSearch;
  faEraser = faEraser;
  faPlus = faPlus;
  faMinus = faMinus;
  faSave = faSave;
  faPaperClip = faPaperclip;
  faCheck = faCheck;
  faFile = faFile;
  faEye = faEye;

  tipos: TipoIncapacidadTemporal[] = [];
  baja: IncapacidadTemporal = new IncapacidadTemporal();
  bajaPendiente: boolean = false;
  hoy = moment();

  rolesUsuario: string[];
  esUsuario: boolean = false;
  expediente: number;
  empleado: Empleado = new Empleado();
  valido: boolean = true;
  fileToUpload: File | null = null;
  esParte: boolean = false;

  @Input() idUsuario: number;
  esRevision: boolean = false;

  @ViewChild('file') inputFile: ElementRef;

  // Tabla
  dataSource: MatTableDataSource<IncapacidadTemporal>;
  displayColumns: string[] = ['expandir', 'tipo', 'fechaIni', 'fechaFin', 'observaciones', 'estado'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: IncapacidadTemporal;

  constructor(public bajasService: BajasService, private datePipe: DatePipe, public translate: TranslateService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");
    let plantaUsuario: TokenPlanta = JSON.parse(ps);
    this.rolesUsuario = plantaUsuario.rol.split(",");
    // Si es el rol "usuario" tiene que rellenar con su expediente y nombre
    if (this.idUsuario) {
      this.esRevision = true;
    } else if (["rrhh_it", "servicio_medico"].some(r => this.rolesUsuario.includes(r))) {
      this.esUsuario = false;
    } else {
      let id = localStorage.getItem("idUsuarioAtenciones") ? localStorage.getItem("idUsuarioAtenciones") : sessionStorage.getItem("idUsuarioAtenciones");
      this.idUsuario = Number.parseInt(id);
      this.esUsuario = true;
    }

    this.bajasService.getTipos().subscribe(result => {
      this.tipos = result;
      // Si es usuario no se muestra el tipo 2
      if (this.esUsuario) {
        this.tipos = this.tipos.filter(t => t.idTipoIncapacidadTemporal != 2);
      }
    });
  }

  buscarEmpleado(empleado: Empleado): void {
    this.empleado = empleado;
    this.buscarBajas();
  }

  buscarBajas(): void {
    this.baja = new IncapacidadTemporal();
    if (this.rolesUsuario.includes("servicio_medico")) {
      this.baja.idTipoIncapacidadTemporal = 2;
    } else {
      this.baja.idTipoIncapacidadTemporal = 1;
    }
    this.bajasService.getBajasUsuario(this.empleado.idUsuario).subscribe(result => {
      result.forEach(element => {
        element.tienePartes = element.incapacidadTemporalAdjuntos.some(ita => ita.idIncapacidadTemporalParteConfirmacion != null);
      });
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.bajaPendiente = result.some(it => it.fechaFin == null);
      if (this.bajaPendiente) {
        this.baja = result.filter(it => it.fechaFin == null)[0];
        this.selectedRow = this.baja;
      }

    });
  }

  subirFichero(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  submitBaja(form: NgForm): void {
    if (form.valid && (this.fileToUpload != null || this.baja.idTipoIncapacidadTemporal == 3)) {
      if (this.esUsuario) {
        this.baja.idEstado = 1;
      } else {
        if (this.baja.fechaFin) {
          this.baja.idEstado = 4;
        } else {
          this.baja.idEstado = 2;
        }
      }
      if (this.esParte) {
        if (this.baja.idIncapacidadTemporal) {
          this.baja.idUsuario = this.empleado.idUsuario;
          this.bajasService.addParteConfirmacion(this.baja, this.fileToUpload).subscribe(result => {
            this.buscarBajas();
            form.resetForm();
            this.limpiar();
          });
        } else {
          this.snackBar.open('Para añadir un parte necesita una I.T. abierta', '', {
            duration: 5 * 1000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      } else {
        if (this.baja.idIncapacidadTemporal) {
          this.bajasService.updateBajaUsuario(this.baja, this.fileToUpload).subscribe(result => {
            this.buscarBajas();
            form.resetForm();
            this.limpiar();
          });
        } else {
          this.baja.idUsuario = this.empleado.idUsuario;
          this.bajasService.addBajaUsuario(this.baja, this.fileToUpload).subscribe(result => {
            this.buscarBajas();
            form.resetForm();
            this.limpiar();
          });
        }
      }
    }
  }

  actualizarDescripcion(): void {
    if (this.baja.idIncapacidadTemporal) {
      this.bajasService.updateBajaUsuario(this.baja, null).subscribe(result => {

      });
    }
  }

  altaIncapacidadTemporal(form: NgForm): void {
    this.esParte = false;
    form.onSubmit(undefined);
  }

  altaParteConfirmacion(form: NgForm): void {
    this.esParte = true;
    form.onSubmit(undefined);
  }

  seleccionar(it: IncapacidadTemporal) {
    if (this.baja.idIncapacidadTemporal == it.idIncapacidadTemporal) {
      this.limpiar();
    } else {
      const myJSON = JSON.stringify(it);

      this.baja = JSON.parse(myJSON);
      this.selectedRow = it;
    }
  }

  limpiar(): void {
    this.baja = new IncapacidadTemporal();
    if (this.rolesUsuario.includes("servicio_medico")) {
      this.baja.idTipoIncapacidadTemporal = 2;
    } else {
      this.baja.idTipoIncapacidadTemporal = 1;
    }

    delete this.selectedRow;
    this.fileToUpload = null;
    this.inputFile.nativeElement.value = '';
  }

  verFichero(id: number): void {
    this.bajasService.getBajaAdjunto(id).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  confirmarBorrar(adjunto: IncapacidadTemporalAdjunto, it: IncapacidadTemporal): void {
    if (it.incapacidadTemporalAdjuntos.length > 1 && adjunto.idEstado == 2 && !adjunto.idIncapacidadTemporalParteConfirmacion) {
      let aviso: Aviso = new Aviso();
      aviso.icono = faExclamationTriangle;
      aviso.claseIcono = "text-warning";
      aviso.titulo = "Atención";
      aviso.mensaje = `No puede eliminar el parte de baja porque existen partes de confirmación`;
      const dialogRef = this.dialog.open(AvisodialogComponent, {
        width: "400px",
        disableClose: true,
        data: aviso
      });
    } else {
      let aviso: Aviso = new Aviso();
      aviso.icono = faExclamationTriangle;
      aviso.claseIcono = "text-warning";
      aviso.titulo = "Atención";
      aviso.mensaje = `Va a eliminar el ${this.getDescripcion(adjunto)}. ¿Desea continuar?`;
      const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
        width: "400px",
        disableClose: true,
        data: aviso
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (it.incapacidadTemporalAdjuntos.length == 1) {
            this.borrar(it.idIncapacidadTemporal);
          } else {
            this.borrarAdjunto(adjunto.idIncapacidadTemporalAdjunto);
          }
        }
      });
    }
  }

  borrarAdjunto(id: number) {
    this.bajasService.deleteBajaAdjunto(id).subscribe(result => {
      if (result) {
        this.limpiar();
        this.buscarBajas();
      }
    }, error => console.error(error));
  }

  borrar(id: number): void {
    this.bajasService.deleteBajaUsuario(id).subscribe(result => {
      if (result) {
        this.limpiar();
        this.buscarBajas();
      }
    }, error => console.error(error));
  }

  clearDate(event: NgModel) {
    event.control.setValue(null);
  }

  getDescripcion(parte: IncapacidadTemporalAdjunto): string {
    if (parte.idIncapacidadTemporalParteConfirmacion) {
      return "Parte Confirmación";
    } else {
      if (parte.idEstado == 2) {
        return "Parte de Baja";
      } else {
        return "Parte de Alta";
      }
    }
  }
}

