import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faTimes, faCheck, faPaperclip, faFileExcel, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Aviso } from 'src/app/model/aviso';
import { Ayuda } from 'src/app/model/ayuda';
import { Empleado } from 'src/app/model/empleado';
import { AyudasService } from 'src/app/services/ayudas.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';
import { DenegardialogComponent } from '../aprobacionhr/denegardialog/denegardialog.component';
import { RevisardialogComponent } from './revisardialog/revisardialog.component';

@Component({
  selector: 'app-revisarayudas',
  templateUrl: './revisarayudas.component.html',
  styleUrls: ['./revisarayudas.component.css']
})
export class RevisarayudasComponent implements OnInit {
  faTimes = faTimes;
  faCheck = faCheck;
  faPaperClip = faPaperclip;
  faExcel = faFileExcel;

    // Tabla
    dataSource: MatTableDataSource<Ayuda>;
    displayColumns: string[] = ['ayuda', 'fecha', 'contrato', 'expediente', 'empleado', 'beneficiario', 'importe', 'adjunto', 'borrar'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ayudasService: AyudasService, public translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buscarAyudasPendientes();
  }

  buscarAyudasPendientes(): void {
    this.ayudasService.getAyudasPendientes().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  validar(empleado: Empleado): void {
    let dialogRef = this.dialog.open(RevisardialogComponent, {
      width: "1200px",
      maxHeight: '90vh',
      data: empleado
    });

    dialogRef.afterClosed().subscribe(result => {
        this.ayudasService.getAyudasPendientes().subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  verFichero(ayuda: Ayuda): void {
    this.ayudasService.getAyudasAdjunto(ayuda.idAyudaAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  confirmarAprobar(ayuda: Ayuda): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "¡¡¡¡Atención!!!!";
    aviso.mensaje = `Va a APROBAR la ayuda. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.revisar(ayuda);
      }
    });
  }

  revisar(ayuda: Ayuda): void {
    ayuda.estado = 1;
    this.ayudasService.modificaAyuda(ayuda).subscribe(result => {
      //this.cerrarPrespuesto(true);
      this.buscarAyudasPendientes();
    });
  }

  confirmarDenegar(ayuda: Ayuda): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a denegar la ayuda. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(DenegardialogComponent, {
      width: "400px",
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        ayuda.observaciones = result;
        this.denegar(ayuda);
      }
    });
  }

  denegar(ayuda: Ayuda): void {
    console.log(ayuda);
    if (ayuda.observaciones) {
      ayuda.estado = 2;
      this.ayudasService.modificaAyuda(ayuda).subscribe(result => {
        this.ayudasService.enviarMailAyuda(ayuda).subscribe();
        this.buscarAyudasPendientes();
      });
    }
  }
}
