<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">AYUDAS ESTUDIOS</h1>
    </div>
    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2 class="text-alcoa fw-bold">CURSOS</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-chip-grid #listaCursos>
                <mat-chip-row *ngFor="let curso of cursos" color="primary" (removed)="borrarCurso(curso)">{{curso.label}}
                    <button matChipRemove class="btn btn-sm btn-alcoa-transparente">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </mat-chip-row>
                <input placeholder="Nuevo Curso" [matChipInputFor]="listaCursos" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addCurso($event)">
            </mat-chip-grid>
        </mat-expansion-panel>
    </mat-accordion>

    <form class="pt-3">
        <div class="row">
            <div class="form-group col-6">
                <h2 class="col-6 col-form-label col-form-label-sm pt-4">Coeficientes:</h2>
            </div>
            <div class="form-group col-2" *ngFor="let c of coeficientes">
                <label for="" class="col-form-label col-form-label-sm fw-bold">{{c.hijos}} Hijos</label>
                <div class="input-group input-group-sm">
                    <input type="number" class="form-control form-control-sm" [(ngModel)]="c.coeficiente" [readonly]="!c.editable" name="coeficiente{{c.hijos}}">
                    <button id="btnAddPropiedad" class="btn btn-outline-secondary" title="Editar Coeficiente" (click)="editarCoeficiente(c)" *ngIf="!c.editable">
                        <fa-icon class="text-alcoa" [icon]="faPenSquare"></fa-icon>
                    </button>
                    <button id="btnAddPropiedad" class="btn btn-outline-secondary" title="Editar Coeficiente" (click)="modificarCoeficiente(c)" *ngIf="c.editable">
                        <fa-icon class="text-alcoa" [icon]="faSave"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="row pt-2">
        <button class="col-1 btn btn-alcoa float-end" (click)="anadir()">
            <fa-icon [icon]="faPlus"></fa-icon> Añadir
        </button>
    </div>
    <div class="pt-1" style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle pt-3">
            <ng-container matColumnDef="curso">
                <th mat-header-cell *matHeaderCellDef> CURSO</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.nombre }} </td>
            </ng-container>
            <ng-container matColumnDef="tipoContrato">
                <th mat-header-cell *matHeaderCellDef> TIPO CONTRATO</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10"> {{ element.tipoContrato }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.importe | currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="importe2">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} 2 Hijos </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ getImporte(2, element) |
                    currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="importe3">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} 3 Hijos</th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ getImporte(3, element) |
                    currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="importe4">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} 4 Hijos</th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ getImporte(4, element) |
                    currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" (click)="editar(row)"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
