import { faCheck, faQuestion, faTicketAlt, faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export class Presupuesto {
    public idPresupuesto: number;
    public idTipoAyuda: number;
    public descTipoAyuda: string;
    public idUsuario: number;
    public idBeneficiario: number;
    public beneficiario: string;
    public fechaNac: string;
    public expediente: string;
    public empleado: string;
    public fecha: Date;
    public fechaStr: string;
    public importe: number;
    public facturado: number;
    public observaciones: string;
    public nombreFichero: string;
    public idPresupuestoAdjunto: number;
    public idEstado: number;
    public estado: string;
    public convenio: string;

    constructor() {}
}
