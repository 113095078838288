<div class="container text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Revisión de presupuestos</h1>
    </div>
    <div class="row pt-3">
        <div class="col-1 text-start">
            <button class="btn btn-excel" (click)="exportar()"><fa-icon [icon]="faExcel"></fa-icon></button>
        </div>
    </div>
    <div style="width:100%; overflow: auto">
        <table mat-table [dataSource]="dataSource" matSort class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle">
            <ng-container matColumnDef="ayuda">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.descTipoAyuda }} </td>
            </ng-container>
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.FECHA' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="element.idEstado == 0 ? seleccionarPresupuesto(element) : return;"> {{ element.fecha | date : 'mediumDate':'':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="empleado">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.EMPLEADO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w25">{{element.empleado}}</td>
            </ng-container>
            <ng-container matColumnDef="contrato">
                <th mat-header-cell *matHeaderCellDef> {{'MODAL.TIPO_CONTRATO' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w10" (click)="validar(element)">{{element.convenio}}</td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{ element.beneficiario }} </td>
            </ng-container>
            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-end pe-3 w10"> {{ element.importe | currency:'EUR':'symbol':'1.2-2':translate.currentLang }} </td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.estado | translate }} </td>
            </ng-container>              
            <ng-container matColumnDef="adjunto">
                <th mat-header-cell *matHeaderCellDef class="w5"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" (click)="verFichero(element)" *ngIf="element.nombreFichero">
                        <fa-icon [icon]="faPaperClip"></fa-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="borrar">
                <th mat-header-cell *matHeaderCellDef class="w10"></th>
                <td mat-cell *matCellDef="let element" class="w5 text-center">
                    <button *ngIf="(element.idEstado == 0 )" class="btn btn-sm btn-success me-1" style="font-size:10px;" (click)="confirmarAprobar(element)">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button *ngIf="(element.idEstado == 0 )" class="btn btn-sm btn-danger " style="font-size:10px;" (click)="confirmarDenegar(element)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
</div>
