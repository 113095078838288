import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigInterceptor implements HttpInterceptor {

  constructor(public route: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = localStorage.getItem("atencionesSocialesToken") ? localStorage.getItem("atencionesSocialesToken") : sessionStorage.getItem("atencionesSocialesToken");
    request = request.clone({
      setHeaders: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          window.location.reload();
        }
      }));
  }
}
