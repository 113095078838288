import { Component, ViewChild, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { LoginComponent } from './components/login/login.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AtencionesSociales';
  logged: boolean;
  token: string;

  @ViewChild('logComponent') loginComponent:LoginComponent;

  public spinkit = Spinkit;

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    if (!isDevMode() && location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
    this.logged = false;
    console.log("Inicio Atenciones Sociales");
    this.token = localStorage.getItem("atencionesSocialesToken") ? localStorage.getItem("atencionesSocialesToken") : sessionStorage.getItem("atencionesSocialesToken");
    if (this.token == null || this.token == "") {
      this.logged = false;
      this.loginComponent.ssoLogin();
    } else {
      this.logged = true;
    }
  }

  setLogged(logueado: boolean) {
    this.logged = logueado;
  }
}
