<h2 mat-dialog-title class="text-alcoa border-bottom border-alcoa-gris"> Nuevo Tipo de Ayuda</h2>
<mat-dialog-content class="mat-typography text-alcoa">
    <div>
        <div class="row">
            <label class="col-sm-3 col-form-label">{{ 'MODAL.TIPO_AYUDA' | translate }}:</label>
            <div class="col-7">
                <select class="form-control form-control-sm" id="tiposAyuda" name="tiposAyuda" [(ngModel)]="tipoAyudaSel" *ngIf="!editar">
                    <option selected></option>
                    <option [ngValue]="tipo" *ngFor="let tipo of tiposAyuda">{{ tipo.descripcion }}</option>
                </select>
                <input type="text" [ngClass]="!valido && !tipoAyuda.descripcion ? 'obligatorio' : '' " class="form-control form-control-sm" id="tipoAyuda" [(ngModel)]="tipoAyuda.descripcion" *ngIf="editar">
            </div>
            <div class="col-2">
                <mat-slide-toggle color="primary" [(ngModel)]="editar" name="nuevo" id="nuevo">Nuevo</mat-slide-toggle>
            </div>
        </div>
        <div [ngClass]="!valido && incluidos == 0 ? 'obligatorio' : '' ">
        <div class="row pt-3" *ngFor="let contrato of tipoContratos; index as i" >
            <div class="col-3 align-middle pt-2">
                <mat-checkbox color="primary" [(ngModel)]="tipoAyuda.tiposContrato[i].incluido" [ngModelOptions]="{standalone: true}">
                    <label class="form-check-label">
                        {{ contrato.label }}
                    </label>
                </mat-checkbox>
            </div>
            <label class="col-2 col-form-label">{{ 'MODAL.COD_NOMINA' | translate }}:</label>
            <div class="col-2">
                <input type="text" class="form-control form-control-sm" [ngClass]="!valido && tipoAyuda.tiposContrato[i].incluido && !tipoAyuda.tiposContrato[i].codigoNomina ? 'obligatorio' : '' " [(ngModel)]="tipoAyuda.tiposContrato[i].codigoNomina" [disabled]="!tipoAyuda.tiposContrato[i].incluido">
            </div>
            <label class="col-2 col-form-label">Porcentaje:</label>
            <div class="col-2">
                <input type="number" min="0" max="100" step="0.5" class="form-control form-control-sm" [ngClass]="!valido && tipoAyuda.tiposContrato[i].incluido && !tipoAyuda.tiposContrato[i].porcentajeAyuda ? 'obligatorio' : '' " [(ngModel)]="tipoAyuda.tiposContrato[i].porcentajeAyuda" [disabled]="!tipoAyuda.tiposContrato[i].incluido">
            </div>
        </div>
    </div>
        <div class="row pt-3">
            <label class="col-sm-3 col-form-label">{{ 'MODAL.EXENTA' | translate }}:</label>
            <div class="col-9">
                <mat-radio-group aria-labelledby="exenta" [(ngModel)]="tipoAyuda.exentaIRPF">
                    <mat-radio-button color="primary" [value]="true">{{ 'MODAL.SI' | translate }}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">{{ 'MODAL.NO' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="border-top border-alcoa-gris">
  <button class="btn btn-alcoa me-2" (click)="guardar()"><fa-icon [icon]="faCheck"></fa-icon> Aceptar</button>
  <button mat-dialog-close class="btn btn-secondary" cdkFocusInitial><fa-icon [icon]="faDoorClosed"></fa-icon> Cancelar</button>
</mat-dialog-actions>
